import { Handle } from 'react-flow-renderer'

export function generateLayout(data) {
  const generationMeterOffset = 140
  const combinerOffset = 140
  const arrayOffset = 90

  let currOffset = 0
  let currConduitLabel = 1

  const newElements = []
  newElements.push(getMeter())
  newElements.push(getEdge('meter', 'servicePanel'))
  newElements.push(getServicePanel())
  newElements.push(getEdge('servicePanel', 'servicePanel-conduitLabel'))
  newElements.push(getConduitLabel('servicePanel-conduitLabel', currConduitLabel++, 163, 133))

  if(data.generationMeter && data.generationMeter.value === 'generation-meter') {
    newElements.push(getEdge('servicePanel-conduitLabel', 'generationMeter'))
    newElements.push(getGenerationMeter())
    newElements.push(getEdge('generationMeter', 'generationMeter-conduitLabel'))
    newElements.push(getConduitLabel('generationMeter-conduitLabel', currConduitLabel++, 303, 133, currOffset))
    newElements.push(getEdge('generationMeter-conduitLabel', 'disconnect'))

    currOffset += generationMeterOffset
  } else {
    newElements.push(getEdge('servicePanel-conduitLabel', 'disconnect'))
  }

  newElements.push(getDisconnect(currOffset))
  newElements.push(getEdge('disconnect', 'disconnect-conduitLabel'))
  newElements.push(getConduitLabel('disconnect-conduitLabel', currConduitLabel++, 303, 133, currOffset))

  if(data.combiner && data.combiner.value === 'envoy-combiner') {
    newElements.push(getEdge('disconnect-conduitLabel', 'combiner'))
    newElements.push(getCombiner(currOffset))
    newElements.push(getEdge('combiner', 'combiner-conduitLabel'))
    newElements.push(getConduitLabel('combiner-conduitLabel', currConduitLabel++, 443, 133, currOffset))
    newElements.push(getEdge('combiner-conduitLabel', 'junction'))

    currOffset += combinerOffset
  } else {
    newElements.push(getEdge('disconnect-conduitLabel', 'junction'))
  }

  newElements.push(getJunction(currOffset))

  if(data.moduleArrays && data.moduleArrays.length > 0) {
    newElements.push(getArrayNotes(currOffset))

    newElements.push(getEdge('junction', 'array-conduitLabel'))
    newElements.push(getConduitLabel('array-conduitLabel', currConduitLabel++, 453, 133, currOffset))

    function composeArray(id) {
      newElements.push(getEdge('array-conduitLabel', 'array-modules-' + id))
      newElements.push(getArray('array-modules-' + id, currOffset))
      currOffset += arrayOffset
    }

    function composeFinalArray(id) {
      newElements.push(getArrayMore(currOffset))
      currOffset += (arrayOffset / 2)
      newElements.push(getEdge('array-conduitLabel', 'array-modules-' + id))
      newElements.push(getArray('array-modules-' + id, currOffset))
    }

    if(data.inverterModel) {
      if(data.inverterModel.type === 'micro') {
        const arrayCount = data.moduleArrays.length
        if (arrayCount === 1) {
          composeArray(1)
        } else if(arrayCount === 2) {
          composeArray(1)
          composeArray(2)
        } else if(arrayCount === 3) {
          composeArray(1)
          composeArray(2)
          composeArray(3)
        } else {
          composeArray(1)
          composeArray(2)
          composeFinalArray(3)
        }
      } else if(data.inverterModel.type === 'string') {
        newElements.push(getEdge('array-conduitLabel', 'optimizer'))
        newElements.push(getOptimizer(currOffset))
      }
    }

  }

  return updateData(newElements, data)
}

export function updateData(elements, data) {
  return elements.map(element => {
    if(element.id === 'meter') {
      return {
        ...element,
        data: {
          meterLife: data.meterLife,
          meterRating: data.meterRating
        }
      }
    } else if(element.id === 'arrayNotes') {
      return {
        ...element,
        data: {
          notes: <div>
            <p>({data.moduleQuantity}) {data.moduleModel && data.moduleModel.label}</p>
            <p>({data.inverterQuantity}) {data.inverterModel && data.inverterModel.label}</p>
            {data.moduleBranches && data.moduleBranches.map((branch, i) =>
              <p key={'branch-' + i}>({branch.quantity}) {parseInt(branch.quantity) === 1 ? 'Branch' : 'Branches'} of ({branch.inverters}) micro-inverters</p>
            )}
          </div>
        }
      }
    } else if(element.id === 'servicePanel') {
      return {
        ...element,
        data: {
          servicePanelLife: data.servicePanelLife,
          servicePanelRating: data.servicePanelRating,
          breakerLife: data.breakerLife,
          breakerRating: data.breakerRating
        }
      }
    } else if(element.id === 'disconnect') {
      return {
        ...element,
        data: {
          disconnectLife: data.disconnectLife,
          disconnectRating: data.disconnectRating,
          disconnectFuseRating: data.disconnectFuseRating
        }
      }
    } else if(element.id === 'junction') {
      return {
        ...element,
        data: {
          junctionLife: data.junctionLife
        }
      }
    } else if(element.id.includes('array-modules-')) {
      return {
        ...element,
        data: {
          inverterModel: data.inverterModel
        }
      }
    } else {
      return element
    }
  })
}

export function getEdge(source, target) {
  return {
    id: source + '-' + target,
    source: source,
    target: target,
    type: 'step',
    style: {
      stroke: '#000000',
      fill: 'none',
      strokeWidth: 1
    }
  }
}

export function getConduitLabel(id, tagID, x, y, currOffset=0) {
  return {
    id: id,
    type: 'conduitLabel',
    position: { x: x + currOffset, y: y },
    data: { tagID: tagID }
  }
}

export function getMeter() {
  return {
    id: 'meter',
    type: 'meter',
    position: { x: 40, y: -80 }
  }
}

export function getServicePanel() {
  return {
    id: 'servicePanel',
    type: 'servicePanel',
    position: { x: 0, y: 0 }
  }
}

export function getGenerationMeter() {
  return {
    id: 'generationMeter',
    type: 'generationMeter',
    position: { x: 200, y: 100 }
  }
}

export function getDisconnect(currOffset=0) {
  return {
    id: 'disconnect',
    type: 'disconnect',
    position: { x: 200 + currOffset, y: 100 }
  }
}

export function getCombiner(currOffset) {
  return {
    id: 'combiner',
    type: 'combiner',
    position: { x: 340 + currOffset, y: 100 }
  }
}

export function getJunction(currOffset) {
  return {
    id: 'junction',
    type: 'junction',
    position: { x: 340 + currOffset, y: 100 }
  }
}

export function getArray(id, currOffset=0) {
  return {
    id: id,
    type: 'array',
    position: { x: 480 + currOffset, y: 0 }
  }
}

export function getArrayMore(currOffset=0) {
  return {
    id: 'arrayMore',
    type: 'arrayMore',
    position: { x: 485 + currOffset, y: 130 }
  }
}

export function getArrayNotes(currOffset=0) {
  return {
    id: 'arrayNotes',
    type: 'arrayNotes',
    position: { x: 490 + currOffset, y: 150 }
  }
}

export function getOptimizer(currOffset=0) {
  return {
    id: 'optimizer',
    type: 'optimizer',
    position: { x: 480 + currOffset, y: 0 }
  }
}

export const Meter = ({ data }) => {
  return (
    <div className='leading-none relative bg-white text-black border border-black flex' style={{ fontSize: '8px', height: 60, width: 60 }}>
      <Handle
        type='source'
        position='bottom'
        style={{ border: 'none', background: 'black', height: 4, width: 1, borderRadius: 0 }}
      />
      {data &&
        <>
          <div className='absolute left-full top-0 pl-1 whitespace-nowrap'>({data.meterLife && data.meterLife.value === 'existing' ? 'E' : 'N'}) {data.meterRating && data.meterRating.label}</div>
          <div className='h-12 w-12 border border-black flex items-center justify-center rounded-full text-3xl font-bold m-auto'>M</div>
        </>
      }
    </div>
  )
}

export const ServicePanel = ({ data }) => {
  return (
    <div className='leading-none bg-white text-black border border-black' style={{ fontSize: '8px', height: 240, width: 140 }}>
      <Handle
        type='target'
        position='top'
        style={{ border: 'none', background: 'black', height: 4, width: 1, borderRadius: 0 }}
      />
      <Handle
        type='source'
        position='right'
        style={{ border: 'none', background: 'black', height: 1, width: 4, borderRadius: 0, marginTop: '20px' }}
      />
      {data &&
        <>
          <div className='p-1' style={{ height: 40 }}>
            ({data.servicePanelLife && data.servicePanelLife.value === 'existing' ? 'E' : 'N'}) {data.servicePanelRating && data.servicePanelRating.label} END FED MAIN PANEL
          </div>
          <div className='absolute left-0 bottom-0 p-1'>
            ({data.breakerLife && data.breakerLife.value === 'existing' ? 'E' : 'N'}) {data.breakerRating && data.breakerRating.label} MAIN BREAKER
          </div>
        </>
      }
    </div>
  )
}

export const GenerationMeter = () => {
  return (
    <div className='leading-none bg-white text-black border border-black' style={{ fontSize: '8px', height: 80, width: 80 }}>
      <Handle
        type='target'
        position='left'
        style={{ border: 'none', background: 'black', height: 1, width: 4, borderRadius: 0 }}
      />
      <Handle
        type='source'
        position='right'
        style={{ border: 'none', background: 'black', height: 1, width: 4, borderRadius: 0 }}
      />
      <div className='p-1'>
        GENERATION METER
      </div>
    </div>
  )
}

export const Disconnect = ({ data }) => {
  return (
    <div className='leading-none bg-white text-black border border-black' style={{ fontSize: '8px', height: 80, width: 80 }}>
      <Handle
        type='target'
        position='left'
        style={{ border: 'none', background: 'black', height: 1, width: 4, borderRadius: 0 }}
      />
      <Handle
        type='source'
        position='right'
        style={{ border: 'none', background: 'black', height: 1, width: 4, borderRadius: 0 }}
      />
      {data &&
        <>
          <div className='p-1'>
            ({data.disconnectLife && data.disconnectLife.value === 'existing' ? 'E' : 'N'}) {data.disconnectRating && data.disconnectRating.label} AC DISCONNECT
          </div>
          <div className='absolute left-0 bottom-0 p-1'>
            {data.disconnectFuseRating && data.disconnectFuseRating.label} FUSE
          </div>
        </>
      }
    </div>
  )
}

export const Combiner = () => {
  return (
    <div className='leading-none bg-white text-black border border-black' style={{ fontSize: '8px', height: 80, width: 80 }}>
      <Handle
        type='target'
        position='left'
        style={{ border: 'none', background: 'black', height: 1, width: 4, borderRadius: 0 }}
      />
      <Handle
        type='source'
        position='right'
        style={{ border: 'none', background: 'black', height: 1, width: 4, borderRadius: 0 }}
      />
      <div className='p-1'>
        COMBINER
      </div>
    </div>
  )
}

export const Junction = ({ data }) => {
  return (
    <div className='leading-none bg-white text-black border border-black' style={{ fontSize: '8px', height: 80, width: 80 }}>
      <Handle
        type='target'
        position='left'
        style={{ border: 'none', background: 'black', height: 1, width: 4, borderRadius: 0 }}
      />
      <Handle
        type='source'
        position='right'
        style={{ border: 'none', background: 'black', height: 1, width: 4, borderRadius: 0 }}
      />
      {data &&
        <div className='p-1'>
          ({data.junctionLife && data.junctionLife.value === 'existing' ? 'E' : 'N'}) JUNCTION BOX OR EQUIV
        </div>
      }
    </div>
  )
}

export const Array = ({ data }) => {
  return (
    <div className='relative leading-none bg-white text-black border border-black' style={{ fontSize: '8px', height: 60, width: 60 }}>
      <Handle
        type='source'
        position='bottom'
        style={{ border: 'none', background: 'black', height: 4, width: 1, borderRadius: 0 }}
      />
      <img className='z-10' src='/img/single-line/array.jpg' alt='Array symbol' />
      <div className='absolute bottom-0 w-full -mb-5'>
        <img className='z-10 w-3 m-auto' src='/img/single-line/array-detail.jpg' alt='Array symbol' />
      </div>
    </div>
  )
}

export const ArrayNotes = ({ data }) => {
  return (
    <div className='leading-none bg-white text-black' style={{ fontSize: '8px' }}>
      {data && data.notes}
    </div>
  )
}

export const ArrayMore = () => {
  return (
    <div className='leading-none bg-white text-black'>
      <img className='z-10 w-3' src='/img/single-line/array-more.jpg' alt='Array more symbol' />
    </div>
  )
}

export const Optimizer = ({ data }) => {
  return (
    <div className='relative border border-black leading-none bg-white text-black' style={{ fontSize: '8px', height: 100, width: 100 }}>
      <Handle
        type='source'
        position='bottom'
        style={{ border: 'none', background: 'black', height: 4, width: 1, borderRadius: 0 }}
      />
      <div className='p-1'>
        PV MODULES
      </div>
      <div className='absolute top-0 right-0 h-full flex'>
        <img className='z-10 w-3 m-auto' src='/img/single-line/array-detail.jpg' alt='Array symbol' />
      </div>
    </div>
  )
}

export const ConduitLabel = ({ data }) => {
  return (
    <div className='leading-none flex bg-white text-xs text-black border border-black rounded-full' style={{ fontSize: '10px', height: 14, width: 14 }}>
      <Handle
        type='target'
        position='left'
        style={{ border: 'none', background: 'black', height: 1, width: 4, borderRadius: 0 }}
      />
      <Handle
        type='source'
        position='right'
        style={{ border: 'none', background: 'black', height: 1, width: 4, borderRadius: 0 }}
      />
      <div className='m-auto'>{data && data.tagID}</div>
    </div>
  )
}

export const nodeTypes = {
  meter: Meter,
  servicePanel: ServicePanel,
  generationMeter: GenerationMeter,
  disconnect: Disconnect,
  combiner: Combiner,
  junction: Junction,
  array: Array,
  arrayMore: ArrayMore,
  arrayNotes: ArrayNotes,
  optimizer: Optimizer,
  conduitLabel: ConduitLabel,
}
