export const servicePanelRatings = [
  {
    value: '100-a',
    label: '100A',
  },{
    value: '150-a',
    label: '150A',
  },{
    value: '200-a',
    label: '200A',
  },{
    value: '225-a',
    label: '225A',
  },{
    value: '400-a',
    label: '400A',
  }
]

export const mainBreakerRatings = [
  {
    value: '100-a',
    label: '100A',
  },{
    value: '150-a',
    label: '150A',
  },{
    value: '175-a',
    label: '175A',
  },{
    value: '200-a',
    label: '200A',
  },{
    value: '225-a',
    label: '225A',
  },{
    value: '400-a',
    label: '400A',
  }
]

export const conduitSizes = [
  {
    value: 'none',
    label: 'NONE',
  },{
    value: 'direct-burial',
    label: 'DIRECT BURIAL',
  },{
    value: '3/4',
    label: '3/4"',
  },{
    value: '1',
    label: '1"',
  },{
    value: '1-1/4',
    label: '1-1/4"',
  },{
    value: '1-1/2',
    label: '1-1/2"',
  },{
    value: '2',
    label: '2"',
  },{
    value: '2-1/2',
    label: '2-1/2"',
  },{
    value: '3',
    label: '3"',
  },{
    value: '4',
    label: '4"',
  }
]

export const wireTypes = [
  {
    value: 'thhn-thwn-2',
    label: 'THHN/THWN-2',
  },{
    value: 'use',
    label: 'USE',
  },{
    value: 'urd',
    label: 'URD',
  },{
    value: 'xhhw',
    label: 'XHHW',
  },{
    value: 'uf',
    label: 'UF',
  },{
    value: 'bare',
    label: 'BARE',
  }
]

export const wireSizes = [
  {
    value: '24-awg',
    label: '24AWG',
  },{
    value: '22-awg',
    label: '22AWG',
  },{
    value: '20-awg',
    label: '20AWG',
  },{
    value: '18-awg',
    label: '18AWG',
  },{
    value: '16-awg',
    label: '16AWG',
  },{
    value: '14-awg',
    label: '14AWG',
  },{
    value: '12-awg',
    label: '12AWG',
  },{
    value: '10-awg',
    label: '10AWG',
  },{
    value: '8-awg',
    label: '8AWG',
  },{
    value: '6-awg',
    label: '6AWG',
  },{
    value: '4-awg',
    label: '4AWG',
  },{
    value: '3-awg',
    label: '3AWG',
  },{
    value: '2-awg',
    label: '2AWG',
  },{
    value: '1-awg',
    label: '1AWG',
  },{
    value: '1-0-awg',
    label: '1/0AWG',
  },{
    value: '2-0-awg',
    label: '2/0AWG',
  },{
    value: '3-0-awg',
    label: '3/0AWG',
  },{
    value: '4-0-awg',
    label: '4/0AWG',
  },{
    value: '250-kcmil',
    label: '250KCMIL',
  },{
    value: '300-kcmil',
    label: '300KCMIL',
  },{
    value: '350-kcmil',
    label: '350KCMIL',
  },{
    value: '400-kcmil',
    label: '400KCMIL',
  }
]

export const conductorTypes = [
  {
    value: 'al',
    label: 'AL',
  },{
    value: 'cu',
    label: 'CU',
  }
]
