import { useAuth } from 'lib/firebase/use-auth'
import { db } from 'lib/firebase/firebase'
import { generateKeywords } from 'lib/keywordGenerator'

import { Prompt } from 'react-router'
import { useHistory } from 'react-router-dom'

import { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

import Layout from 'components/layout'
import MapVicinity from 'components/plans-id/leaflet-map-vicinity'
import MapAerial from 'components/plans-id/leaflet-map-aerial'
import PdfDocument from 'components/plans-id/document'
import SingleLineSnapShot from 'components/plans-id/single-line-snap-shot'
import SingleLine from 'components/plans-id/single-line'
import { UploadDiagram } from 'components/plans-id/upload-diagram'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'

import Input from 'components/input'
import MySelect from 'components/select'

import Modal from 'react-modal'
import { toast } from 'react-toastify'

import computedStyleToInlineStyle from 'computed-style-to-inline-style'
import html2canvas from 'html2canvas'
import { useDebouncedCallback } from 'use-debounce'
import { saveAs } from 'file-saver'
import { pdf } from '@react-pdf/renderer'

import { electricalCodes, fireCodes, buildingCodes, residentialCodes } from 'lib/codes'
import { moduleOptions, inverterOptions, rackOptions, batteryOptions } from 'lib/materials'
import { roofTypeOptions, roofExposureOptions } from 'lib/roofs'
import { servicePanelRatings, mainBreakerRatings, conduitSizes, wireSizes, wireTypes } from 'lib/electrical'

export default function Index() {
  useAuth()
  const history = useHistory()
  const { id } = useParams()

  const toastRef = useRef(null)
  const [data, setData] = useState(false)
  const [dataLoaded, setDataLoaded] = useState(false)
  const [dataUpdated, setDataUpdated] = useState(false)

  const [pdfLoaded, setPdfLoaded] = useState(false)
  const [conduitScheduleLength, setConduitScheduleLength] = useState(3)

  useEffect(() => {
    async function fetchData() {
      const doc = await db.collection('plans').doc(id).get()
      if (doc.exists) {
        setData({...doc.data(), id: doc.id})
        setDataLoaded(true)
        fetchMaps(doc.data())
      }
    }
    fetchData()
    Modal.setAppElement('body')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    function toastContent(data) {
      return (
        <div className='flex items-center'>
          <button className='btn-primary mr-3' onClick={() => save(data)}>Save</button>
          <span className='leading-none block -mb-1'>
            Your post has changes!<br />
            <span className='text-xs text-gray-700'>This will auto save after a bit.</span>
          </span>
        </div>
      )
    }
    if(dataLoaded) {
      updateArraysLength()
      updateBranchesLength()
      updateSeriesLength()
      updateConduitScheduleLength()
    }
    if(dataLoaded && dataUpdated) {
      onSave(data)
      const toastSettings = {
        className: () => {
          return 'bg-gray-900 rounded-xl border-2 border-gray-800 flex justify-between text-white p-3'
        },
        bodyClassName: () => {
          return 'text-sm'
        },
        position: 'bottom-left',
        closeButton: false,
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined
      }
      if(!toastRef.current) {
        toastRef.current = toast(toastContent(data), toastSettings)
      } else {
        toast.update(toastRef.current, {
          render: () => toastContent(data),
          ...toastSettings
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dataUpdated])

  useEffect(() => {
    if(dataLoaded && data) fetchMaps(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.mapAerialState, data.mapVicinityState])

  useEffect(() => {
    if(pdfLoaded === 'preview') setTimeout(() => generatePreview(), 100)
    if(pdfLoaded === 'download') setTimeout(() => generateFile(), 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfLoaded])

  const fetchMaps = (newData) => {
    const {lat, lng} = newData.location

    const aLat = newData.mapAerialState.lat
    const aLng = newData.mapAerialState.lng
    const aZoom = newData.mapAerialState.zoom

    const vLat = newData.mapVicinityState.lat
    const vLng = newData.mapVicinityState.lng
    const vZoom = newData.mapVicinityState.zoom

    const aUrl = 'https://maps.googleapis.com/maps/api/staticmap?center=' + aLat + ',' + aLng + '&markers=' + lat + ',' + lng + '&zoom=' + aZoom + '&size=400x400&maptype=satellite&key=' + process.env.REACT_APP_G_API_KEY
    fetch(aUrl)
    .then(data => {
      setData(prevState => ({ ...prevState, mapAerialStatic: data.url }))
    })

    const vUrl = 'https://maps.googleapis.com/maps/api/staticmap?center=' + vLat + ',' + vLng + '&markers=' + lat + ',' + lng + '&zoom=' + vZoom + '&size=400x400&key=' + process.env.REACT_APP_G_API_KEY
    fetch(vUrl)
    .then(data => {
      setData(prevState => ({ ...prevState, mapVicinityStatic: data.url }))
    })
  }

  const addArray = () => {
    const moduleArrays = data.moduleArrays || []
    moduleArrays.push({
      id: moduleArrays.length,
      uid: Date.now()
    })
    onChange(moduleArrays, 'moduleArrays')
  }

  const removeArray = (id) => {
    const moduleArrays = data.moduleArrays || []
    const newModuleArrays = moduleArrays.filter(array => {
      if(array.id === id) return false
      return true
    })
    .sort((a, b) => a.id > b.id ? 1 : -1)
    .map((array, index) => { return { ...array, id: index } })
    onChange(newModuleArrays, 'moduleArrays')
  }

  const onChangeArrays = (value, name, id) => {
    const newModuleArrays = data.moduleArrays.map(array => {
      if(array.id === id) {
        return {
          ...array,
          [name]: value
        }
      } else {
        return array
      }
    })
    onChange(newModuleArrays, 'moduleArrays')
  }

  const addSeries = () => {
    const moduleSeries = data.moduleSeries || []
    moduleSeries.push({
      id: moduleSeries.length,
      uid: Date.now()
    })
    onChange(moduleSeries, 'moduleSeries')
  }

  const removeSeries = (id) => {
    const moduleSeries = data.moduleSeries || []
    const newModuleSeries = moduleSeries.filter(series => {
      if(series.id === id) return false
      return true
    })
    .sort((a, b) => a.id > b.id ? 1 : -1)
    .map((series, index) => { return { ...series, id: index } })
    onChange(newModuleSeries, 'moduleSeries')
  }

  const onChangeSeries = (value, name, id) => {
    const newModuleSeries = data.moduleSeries.map(series => {
      if(series.id === id) {
        return {
          ...series,
          [name]: value
        }
      } else {
        return series
      }
    })
    onChange(newModuleSeries, 'moduleSeries')
  }

  const addBranch = () => {
    const moduleBranches = data.moduleBranches || []
    moduleBranches.push({
      id: moduleBranches.length,
      uid: Date.now()
    })
    onChange(moduleBranches, 'moduleBranches')
  }

  const removeBranch = (id) => {
    const moduleBranches = data.moduleBranches || []
    const newModuleBranches = moduleBranches.filter(branch => {
      if(branch.id === id) return false
      return true
    })
    .sort((a, b) => a.id > b.id ? 1 : -1)
    .map((branch, index) => { return { ...branch, id: index } })
    onChange(newModuleBranches, 'moduleBranches')
  }

  const onChangeBranches = (value, name, id) => {
    const newModuleBranches = data.moduleBranches.map(branch => {
      if(branch.id === id) {
        return {
          ...branch,
          [name]: value
        }
      } else {
        return branch
      }
    })
    onChange(newModuleBranches, 'moduleBranches')
  }

  const onChangeConduitSchedule = (value, name, uid) => {
    const newConduitSchedule = data.conduitSchedule.map(item => {
      if(item.uid === uid) {
        return {
          ...item,
          [name]: value
        }
      } else {
        return item
      }
    })
    onChange(newConduitSchedule, 'conduitSchedule')
  }

  const updateConduitScheduleLength = () => {
    let length = 3

    if(data.generationMeter && data.generationMeter.value === 'generation-meter') length++
    if(data.combiner && data.combiner.value === 'envoy-combiner') length++

    function getNewSection(i) {
      return ({
        id: i,
        uid: Date.now() + i
      })
    }

    let newConduitSchedule = data.conduitSchedule ? data.conduitSchedule : []
    if(newConduitSchedule.length > 0) {
      const offset = length - newConduitSchedule.length
      if(offset > 0) {
        [...Array(offset)].forEach((e, i) =>
          newConduitSchedule.push(getNewSection(i))
        )
        onChange(newConduitSchedule, 'conduitSchedule')
      }
    } else {
      [...Array(length)].forEach((e, i) =>
        newConduitSchedule.push(getNewSection(i))
      )
      onChange(newConduitSchedule, 'conduitSchedule')
    }
    setConduitScheduleLength(length)
  }

  const updateArraysLength = () => {
    if(!data.moduleArrays) addArray()
  }

  const updateBranchesLength = () => {
    if(!data.moduleBranches) addBranch()
  }

  const updateSeriesLength = () => {
    if(!data.moduleSeries) addSeries()
  }

  const getSingleLineSnapshot = () => {
    return html2canvas(document.querySelector('#single-line-flow-chart-snap-shot'), {
      onclone: (document) => {
        console.log(document)
        computedStyleToInlineStyle(document.querySelector('.react-flow__edges'), {
          recursive: true
        })
      },
      scrollX: 0,
      // scrollY: window.scrollY,
      scrollY: 0,
      useCORS: true,
    }).then(canvas => {
      console.log(canvas)
      return canvas.toDataURL('image/png')
    })
  }

  const generatePreview = async () => {
    const singleLine = await getSingleLineSnapshot()
    const doc = <PdfDocument data={data} singleLine={singleLine} />
    const asPdf = pdf([])
    asPdf.updateContainer(doc)
    const blob = await asPdf.toBlob()
    const url = URL.createObjectURL(blob)
    window.open(url, '_blank')
    setPdfLoaded(false)
  }

  const generateFile = async () => {
    const singleLine = await getSingleLineSnapshot()
    const doc = <PdfDocument data={data} singleLine={singleLine} />
    const asPdf = pdf([])
    asPdf.updateContainer(doc)
    const blob = await asPdf.toBlob()
    saveAs(blob, 'document.pdf')
    setPdfLoaded(false)
  }

  const onChange = (value, name) => {
    setDataUpdated(true)
    setData(prevState => ({ ...prevState, [name]: value }))
  }

  const onSave = useDebouncedCallback((newData) => {
    save(newData)
  }, 10000)

  const save = async (newData) => {
    onSave.cancel()
    await db.collection('plans').doc(newData.id).update({
      ...newData,
      id: undefined,
      keywords: generateKeywords([data.name, data.location.address]),
      updatedAt: new Date()
    })
    setDataUpdated(false)
    toast.dismiss(toastRef.current)
    toastRef.current = null
  }

  const onRemove = async () => {
    await db.collection('plans').doc(id).delete()
    history.push('/plans')
  }

  return (
    <Layout>
      <Prompt
        when={dataUpdated}
        message='You have unsaved changes, are you sure you want to leave?'
      />
      <main>
        <div className='fixed flex right-0 bottom-0 m-6'>
          <button
            className='btn-gray flex items-center'
            onClick={() => setPdfLoaded('preview')}>
            {pdfLoaded === 'preview' ?
              'Loading...' :
              'Preview'
            }
          </button>
          <button
            className='btn-gray flex items-center ml-2'
            onClick={() => setPdfLoaded('download')}>
            {
              pdfLoaded === 'download' ?
              'Loading...' :
              <svg className='h-5 w-5' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z' />
              </svg>
            }
          </button>
        </div>

        <div className='fixed top-0 left-full'>
          <SingleLineSnapShot data={data} />
        </div>

        {data &&
          <div className='fade-in p-6 py-32'>

            <div className='max-w-lg mx-auto bg-gray-900 rounded-xl mb-12'>
              <div className='p-3 pb-12 leading-tight text-sm flex items-center'>
                <span>
                  <span className='text-gray-600'>General</span><br />
                  Location
                </span>
              </div>
              <div className='grid grid-cols-1 divide-y-2 divide-gray-800 border-t-2 border-gray-800'>
                <Input
                  type='text'
                  icon=
                  <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z' />
                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M15 11a3 3 0 11-6 0 3 3 0 016 0z' />
                  </svg>
                  value={data.location.address}
                  name='address'
                  label='Address'
                  placeholder='None'
                  onChange={(e) => onChange(e.target.value, 'address')}
                />
                <Input
                  type='text'
                  icon=
                    <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                      <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z' />
                    </svg>
                  value={data.name}
                  name='name'
                  label='Name'
                  placeholder='None'
                  onChange={(e) => onChange(e.target.value, 'name')}
                />
                <Input
                  type='text'
                  icon=
                    <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                      <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z' />
                    </svg>
                  value={data.utility}
                  name='utility'
                  label='Utility'
                  placeholder='None'
                  onChange={(e) => onChange(e.target.value, 'utility')}
                />
                <Input
                  type='text'
                  icon=
                    <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                      <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4' />
                    </svg>
                  value={data.numberAHJ}
                  name='numberAHJ'
                  label='AHJ'
                  placeholder='None'
                  onChange={(e) => onChange(e.target.value, 'numberAHJ')}
                />
              </div>
            </div>

            <div className='max-w-lg mx-auto bg-gray-900 rounded-xl mb-12'>
              <div className='p-3 pb-12 leading-tight text-sm flex items-center'>
                <span>
                  <span className='text-gray-600'>General</span><br />
                  Views
                </span>
              </div>
              <div className='grid grid-cols-2 gap-3 p-3 pt-0'>
                <MapAerial
                  lat={data.mapAerialState.lat}
                  lng={data.mapAerialState.lng}
                  zoom={data.mapAerialState.zoom}
                  marker={[data.location.lat, data.location.lng]}
                  onChange={(state) => onChange(state, 'mapAerialState')} />
                <MapVicinity
                  lat={data.mapVicinityState.lat}
                  lng={data.mapVicinityState.lng}
                  zoom={data.mapVicinityState.zoom}
                  marker={[data.location.lat, data.location.lng]}
                  onChange={(state) => onChange(state, 'mapVicinityState')} />
              </div>
            </div>

            <div className='mb-12 mx-auto max-w-lg bg-gray-900 rounded-xl'>
              <div className='p-3 pb-12 leading-tight text-sm'>
                <span className='text-gray-600'>General</span><br />
                Site Information
              </div>
              <Tabs selectedTabClassName='bg-gray-900 border-2 border-primary'>
                <TabList className='border-b-2 border-gray-800 px-2 pb-6 whitespace-nowrap text-center grid grid-cols-3 gap-1'>
                  <Tab className='cursor-pointer px-4 border-2 border-gray-800 hover:border-primary transition duration-150 rounded-full text-sm py-1'>Codes</Tab>
                  <Tab className='cursor-pointer px-4 border-2 border-gray-800 hover:border-primary transition duration-150 rounded-full text-sm py-1'>Mounting Type</Tab>
                  <Tab className='cursor-pointer px-4 border-2 border-gray-800 hover:border-primary transition duration-150 rounded-full text-sm py-1'>Environment</Tab>
                </TabList>
                <TabPanel key='codes'>
                  <div className='divide-y-2 divide-gray-800'>
                    <MySelect
                      icon=
                        <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5} d='M13 10V3L4 14h7v7l9-11h-7z' />
                        </svg>
                      value={data.codeElectrical}
                      name='codeElectrical'
                      label='Electrical Code'
                      onChange={(e) => onChange(e, 'codeElectrical')}
                      options={electricalCodes}
                    />
                    <MySelect
                      icon=
                        <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z' />
                          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z' />
                        </svg>
                      value={data.codeFire}
                      name='codeFire'
                      label='Fire Code'
                      onChange={(e) => onChange(e, 'codeFire')}
                      options={fireCodes}
                    />
                    <MySelect
                      icon=
                        <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4' />
                        </svg>
                      value={data.codeBuilding}
                      name='codeBuilding'
                      label='Building Code'
                      onChange={(e) => onChange(e, 'codeBuilding')}
                      options={buildingCodes}
                    />
                    <MySelect
                      icon=
                        <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6' />
                        </svg>
                      value={data.codeResidential}
                      name='codeResidential'
                      label='Residential Code'
                      onChange={(e) => onChange(e, 'codeResidential')}
                      options={residentialCodes}
                    />
                  </div>
                </TabPanel>
                <TabPanel key='mounting-type'>
                  <div className='grid grid-cols-2 gap-3 p-3'>
                    <div>
                      <button
                        onClick={(e) => onChange('Roof Mount', 'mountingType')}
                        className={`w-full border-2 border-gray-800 hover:border-primary rounded-xl transition duration-150 py-8 leading-none relative focus:outline-none ${data.mountingType === 'Roof Mount' && 'bg-gray-800'}`}>
                        <svg className={`w-20 m-auto ${data.mountingType !== 'Roof Mount' ? 'text-gray-700' : 'text-primary'}`} stroke='currentColor' fill='none' strokeWidth={1.75} strokeLinecap='round' version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 85.56 43.49'>
                          <polyline points='0.29,29.53 40.29,0.62 80.29,29.53 '/>
                          <path d='M10.95,21.72c-3.16-4.38-2.18-10.5,2.2-13.66s10.5-2.18,13.66,2.2'/>
                          <line x1='13.15' y1='8.06' x2='10.33' y2='4.15'/>
                          <line x1='22.96' y1='7.25' x2='25.13' y2='2.16'/>
                          <line x1='8.62' y1='17.22' x2='3.98' y2='17.82'/>
                        </svg>
                        <span className={`-mt-2 text-sm block leading-none m-auto ${data.mountingType !== 'Roof Mount' ? 'text-gray-700' : 'currentColor'}`}>
                          Roof<br />Mount
                        </span>
                        <span className={`text-xs mt-4 mx-6 block leading-none m-auto ${data.mountingType !== 'Roof Mount' ? 'text-gray-700' : 'text-primary'}`}>
                          Adds array roof details and removes site plot lines diagram.
                        </span>
                      </button>
                    </div>
                    <div>
                      <button
                        onClick={(e) => onChange('Ground Mount', 'mountingType')}
                        className={`w-full border-2 border-gray-800 hover:border-primary rounded-xl transition duration-150 py-8 leading-none relative focus:outline-none ${data.mountingType === 'Ground Mount' && 'bg-gray-800'}`}>
                        <svg className={`w-20 m-auto ${data.mountingType !== 'Ground Mount' ? 'text-gray-700' : 'text-primary'}`} stroke='currentColor' fill='none' strokeWidth={1.75} strokeLinecap='round' version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 85.56 43.49'>
                          <path d='M0.33,28.92c10.61-9.52,24.63-15.31,40-15.31c15.37,0,29.39,5.79,40,15.31'/>
                          <path d='M30.55,14.61c0-5.4,4.38-9.78,9.78-9.78s9.78,4.38,9.78,9.78'/>
                          <line x1='40.34' y1='4.83' x2='40.34' y2='0'/>
                          <line x1='48.75' y1='9.91' x2='53.49' y2='7.06'/>
                          <line x1='31.29' y1='9.6' x2='27.18' y2='7.37'/>
                        </svg>
                        <span className={`-mt-2 text-sm block leading-none m-auto ${data.mountingType !== 'Ground Mount' ? 'text-gray-700' : 'currentColor'}`}>
                          Ground<br />Mount
                        </span>
                        <span className={`text-xs mt-4 mx-6 block leading-none m-auto ${data.mountingType !== 'Ground Mount' ? 'text-gray-700' : 'text-primary'}`}>
                          Removes array roof details and adds site plot lines diagram.
                        </span>
                      </button>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel key='environment'>
                  <div className='divide-y-2 divide-gray-800'>
                    <Input
                      type='number'
                      icon=
                        <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                        </svg>
                      value={data.windSpeed}
                      name='windSpeed'
                      label='Wind Speed (mph)'
                      placeholder='None'
                      onChange={(e) => onChange(e.target.value, 'windSpeed')}
                    />
                    <Input
                      type='number'
                      icon=
                        <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                        </svg>
                      value={data.snowLoad}
                      name='snowLoad'
                      label='Snow Load (psf)'
                      placeholder='None'
                      onChange={(e) => onChange(e.target.value, 'snowLoad')}
                    />
                  </div>
                </TabPanel>
              </Tabs>
            </div>

            <div className='mb-12 mx-auto max-w-lg bg-gray-900 rounded-xl'>
              <div className='p-3 pb-12 leading-tight text-sm'>
                <span className='text-gray-600'>Design</span><br />
                Materials
              </div>
              <Tabs selectedTabClassName='bg-gray-900 border-2 border-primary'>
                <TabList className='border-b-2 border-gray-800 px-2 pb-6 whitespace-nowrap text-center grid grid-cols-4 gap-1'>
                  <Tab className='cursor-pointer px-4 border-2 border-gray-800 hover:border-primary transition duration-150 rounded-full text-sm py-1'>Modules</Tab>
                  <Tab className='cursor-pointer px-4 border-2 border-gray-800 hover:border-primary transition duration-150 rounded-full text-sm py-1'>Racking</Tab>
                  <Tab className='cursor-pointer px-4 border-2 border-gray-800 hover:border-primary transition duration-150 rounded-full text-sm py-1'>Inverters</Tab>
                  <Tab className='cursor-pointer px-4 border-2 border-gray-800 hover:border-primary transition duration-150 rounded-full text-sm py-1'>Batteries</Tab>
                </TabList>
                <TabPanel key='modules'>
                  <div className='divide-y-2 divide-gray-800'>
                    <MySelect
                      icon=
                        <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                        </svg>
                      value={data.moduleModel}
                      name='moduleModel'
                      label='Type'
                      onChange={(e) => onChange(e, 'moduleModel')}
                      options={moduleOptions}
                    />
                    <Input
                      icon=
                        <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                        </svg>
                      type='number'
                      value={data.moduleQuantity}
                      name='moduleQuantity'
                      label='# Modules'
                      placeholder='None'
                      onChange={(e) => onChange(e.target.value, 'moduleQuantity')}
                    />
                  </div>
                </TabPanel>
                <TabPanel key='racking'>
                  <div className='divide-y-2 divide-gray-800'>
                    <MySelect
                      icon=
                        <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                        </svg>
                      value={data.rackingModel}
                      name='rackingModel'
                      label='Type'
                      onChange={(e) => onChange(e, 'rackingModel')}
                      options={rackOptions}
                    />
                    {data.rackingModel && data.rackingModel.includes && false &&
                      <div className='p-3'>
                        <div className='p-2 leading-tight text-gray-600 text-xs bg-gray-800 rounded-xl'>
                          <div className='mb-2'>Includes:</div>
                          {data.rackingModel.includes.map((option, i) =>
                            <div key={data.rackingModel.label + '-rack-includes-' + i} className='truncate'>{option.label}</div>
                          )}
                        </div>
                      </div>
                    }
                  </div>
                </TabPanel>
                <TabPanel key='inverters'>
                  <div className='divide-y-2 divide-gray-800'>
                    <MySelect
                      icon=
                        <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                        </svg>
                      value={data.inverterModel}
                      name='inverterModel'
                      label='Type'
                      onChange={(e) => onChange(e, 'inverterModel')}
                      options={inverterOptions}
                    />
                    <Input
                      icon=
                        <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                        </svg>
                      type='number'
                      value={data.inverterQuantity}
                      name='inverterQuantity'
                      label='# Inverters'
                      placeholder='None'
                      onChange={(e) => onChange(e.target.value, 'inverterQuantity')}
                    />
                    {data.inverterModel && data.inverterModel.includes && false &&
                      <div className='p-3'>
                        <div className='p-2 leading-tight text-gray-600 text-xs bg-gray-800 rounded-xl'>
                          <div className='mb-2'>Includes:</div>
                          {data.inverterModel.includes.map((option, i) =>
                            <div key={data.inverterModel + '-inverter-includes-' + i} className='truncate'>{option.label}</div>
                          )}
                        </div>
                      </div>
                    }
                  </div>
                </TabPanel>
                <TabPanel key='batteries'>
                  <div className='divide-y-2 divide-gray-800'>
                    <MySelect
                      icon=
                        <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                        </svg>
                      value={data.batteryModel}
                      name='batteryModel'
                      label='Type'
                      onChange={(e) => onChange(e, 'batteryModel')}
                      options={batteryOptions}
                    />
                    <Input
                      icon=
                        <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                        </svg>
                      type='number'
                      value={data.batteryQuantity}
                      name='batteryQuantity'
                      label='# Batteries'
                      placeholder='None'
                      onChange={(e) => onChange(e.target.value, 'batteryQuantity')}
                    />
                    {data.batteryModel && data.batteryModel.includes && false &&
                      <div className='p-3'>
                        <div className='p-2 leading-tight text-gray-600 text-xs bg-gray-800 rounded-xl'>
                          <div className='mb-2'>Includes:</div>
                          {data.batteryModel.includes.map((option, i) =>
                            <div key={data.batteryModel + '-battery-includes-' + i} className='truncate'>{option.label}</div>
                          )}
                        </div>
                      </div>
                    }
                  </div>
                </TabPanel>
              </Tabs>
            </div>

            {data.moduleArrays &&
              <div className='mb-12 mx-auto max-w-lg bg-gray-900 rounded-xl'>
                <div className='p-3 pb-12 leading-tight text-sm'>
                  <span className='text-gray-600'>Design</span><br />
                  Module Arrays
                </div>
                <Tabs selectedTabClassName='bg-gray-900 border-2 border-primary'>
                  <div className={`flex items-center pb-6 ${data.mountingType === 'Ground Mount' && 'border-b-2 border-gray-800'}`}>
                    <TabList className='flex-1 px-2 pr-6 whitespace-nowrap text-center grid grid-cols-6 gap-1'>
                      {data.moduleArrays.map((array, i) =>
                        <Tab key={'arrays-tabs-' + i} className='cursor-pointer px-4 border-2 border-gray-800 hover:border-primary transition duration-150 rounded-full text-sm py-1'>{('00' + (array.id + 1)).slice(-2)}</Tab>
                      )}
                    </TabList>
                    <button
                      onClick={() => addArray()}
                      className='btn-circle-gray-800 mr-2'>
                      <svg className='h-6 w-6' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                        <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5} d='M12 6v6m0 0v6m0-6h6m-6 0H6' />
                      </svg>
                    </button>
                  </div>
                  {data.moduleArrays.map((array, i) =>
                    <TabPanel key={'arrays-tabs-panel' + i}>
                      <div className='divide-y-2 divide-gray-800'>
                        {data.mountingType === 'Roof Mount' ?
                          <Tabs selectedTabClassName='text-primary bg-gray-800'>
                            <TabList className='border-b-2 border-gray-800 px-3 whitespace-nowrap grid gap-1 grid-flow-col auto-cols-max border-b-2 border-gray-800'>
                              <Tab className='cursor-pointer border-2 border-gray-800 hover:text-primary border-b-0 rounded-t-xl px-4 py-2 transition duration-150 text-sm focus:outline-none'>General</Tab>
                              <Tab className='cursor-pointer border-2 border-gray-800 hover:text-primary border-b-0 rounded-t-xl px-4 py-2 transition duration-150 text-sm focus:outline-none'>Roof</Tab>
                            </TabList>
                            <TabPanel key='general-inputs'>
                              <div className='divide-y-2 divide-gray-800'>
                                <Input
                                  icon=
                                    <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                      <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                                    </svg>
                                  type='number'
                                  value={array.azimuth}
                                  name={'azimuth-' + array.uid}
                                  label='Azimuth (deg)'
                                  placeholder='None'
                                  onChange={(e) => onChangeArrays(e.target.value, 'azimuth', array.id)}
                                />
                                <Input
                                  icon=
                                    <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                      <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                                    </svg>
                                  type='number'
                                  value={array.pitch}
                                  name={'pitch-' + array.uid}
                                  label='Pitch (deg)'
                                  placeholder='None'
                                  onChange={(e) => onChangeArrays(e.target.value, 'pitch', array.id)}
                                />
                                <Input
                                  icon=
                                    <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                      <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                                    </svg>
                                  type='number'
                                  value={array.moduleCount}
                                  name={'moduleCount-' + array.uid}
                                  label='# Modules'
                                  placeholder='None'
                                  onChange={(e) => onChangeArrays(e.target.value, 'moduleCount', array.id)}
                                />
                                <MySelect
                                  icon=
                                    <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                      <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                                    </svg>
                                  value={array.attachment}
                                  name={'attachment-' + array.uid}
                                  label='Attachment'
                                  onChange={(e) => onChangeArrays(e, 'attachment', array.id)}
                                  options={rackOptions}
                                />
                              </div>
                            </TabPanel>
                            <TabPanel key='roof-inputs'>
                              <div className='divide-y-2 divide-gray-800'>
                                <MySelect
                                  icon=
                                    <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                      <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                                    </svg>
                                  value={array.roofType}
                                  name={'roofType-' + array.uid}
                                  label='Roof Type'
                                  onChange={(e) => onChangeArrays(e, 'roofType', array.id)}
                                  options={roofTypeOptions}
                                />
                                <Input
                                  icon=
                                    <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                      <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                                    </svg>
                                  type='number'
                                  value={array.area}
                                  name={'area-' + array.uid}
                                  label='Area (sqft)'
                                  placeholder='None'
                                  onChange={(e) => onChangeArrays(e.target.value, 'area', array.id)}
                                />
                                <MySelect
                                  icon=
                                    <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                      <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                                    </svg>
                                  value={array.roofExposure}
                                  name={'roofExposure-' + array.uid}
                                  label='Roof Exposure'
                                  placeholder='None'
                                  onChange={(e) => onChangeArrays(e, 'roofExposure', array.id)}
                                  options={roofExposureOptions}
                                />
                                <Input
                                  icon=
                                    <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                      <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                                    </svg>
                                  type='text'
                                  value={array.frameType}
                                  name={'frameType-' + array.uid}
                                  label='Frame Type'
                                  placeholder='None'
                                  onChange={(e) => onChangeArrays(e.target.value, 'frameType', array.id)}
                                />
                                <Input
                                  icon=
                                    <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                      <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                                    </svg>
                                  type='text'
                                  value={array.frameSize}
                                  name={'frameSize-' + array.uid}
                                  label='Frame Size'
                                  placeholder='None'
                                  onChange={(e) => onChangeArrays(e.target.value, 'frameSize', array.id)}
                                />
                                <Input
                                  icon=
                                    <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                      <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                                    </svg>
                                  type='text'
                                  value={array.frameSpacing}
                                  name={'frameSpacing-' + array.uid}
                                  label='Frame Spacing'
                                  placeholder='None'
                                  onChange={(e) => onChangeArrays(e.target.value, 'frameSpacing', array.id)}
                                />
                                <Input
                                  icon=
                                    <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                      <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                                    </svg>
                                  type='text'
                                  value={array.railSpan}
                                  name={'railSpan-' + array.uid}
                                  label='Rail Span'
                                  placeholder='None'
                                  onChange={(e) => onChangeArrays(e.target.value, 'railSpan', array.id)}
                                />
                                <Input
                                  icon=
                                    <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                      <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                                    </svg>
                                  type='text'
                                  value={array.overHang}
                                  name={'overHang-' + array.uid}
                                  label='Over Hang'
                                  placeholder='None'
                                  onChange={(e) => onChangeArrays(e.target.value, 'overHang', array.id)}
                                />
                              </div>
                            </TabPanel>
                          </Tabs>
                          :
                          <>
                            <Input
                              icon=
                                <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                  <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                                </svg>
                              type='number'
                              value={array.azimuth}
                              name={'azimuth-' + array.uid}
                              label='Azimuth (deg)'
                              placeholder='None'
                              onChange={(e) => onChangeArrays(e.target.value, 'azimuth', array.id)}
                            />
                            <Input
                              icon=
                                <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                  <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                                </svg>
                              type='number'
                              value={array.pitch}
                              name={'pitch-' + array.uid}
                              label='Pitch (deg)'
                              placeholder='None'
                              onChange={(e) => onChangeArrays(e.target.value, 'pitch', array.id)}
                            />
                            <Input
                              icon=
                                <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                  <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                                </svg>
                              type='number'
                              value={array.moduleCount}
                              name={'moduleCount-' + array.uid}
                              label='# Modules'
                              placeholder='None'
                              onChange={(e) => onChangeArrays(e.target.value, 'moduleCount', array.id)}
                            />
                            <MySelect
                              icon=
                                <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                  <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                                </svg>
                              value={array.attachment}
                              name={'attachment-' + array.uid}
                              label='Attachment'
                              onChange={(e) => onChangeArrays(e, 'attachment', array.id)}
                              options={rackOptions}
                            />
                          </>
                        }
                      </div>
                      <div className='p-2 pt-6'>
                        <button
                          onClick={(e) => removeArray(array.id)}
                          className='btn-gray-800 text-sm'>
                          Remove Array
                        </button>
                      </div>
                    </TabPanel>
                  )}
                </Tabs>
              </div>
            }

            {data.inverterModel && data.inverterModel.type === 'micro' &&
              <div className='mb-12 mx-auto max-w-lg bg-gray-900 rounded-xl'>
                <div className='p-3 pb-12 leading-tight text-sm'>
                  <span className='text-gray-600'>Design</span><br />
                  Branch Types
                </div>
                <Tabs selectedTabClassName='bg-gray-900 border-2 border-primary'>
                  <div className='flex items-center pb-6 border-b-2 border-gray-800 '>
                    <TabList className='flex-1 px-2 pr-6 whitespace-nowrap text-center grid grid-cols-6 gap-1'>
                      {data.moduleBranches.map((branch, i) =>
                        <Tab key={'branches-tabs-' + i} className='cursor-pointer px-4 border-2 border-gray-800 hover:border-primary transition duration-150 rounded-full text-sm py-1'>B{branch.id + 1}</Tab>
                      )}
                    </TabList>
                    <button
                      onClick={() => addBranch()}
                      className='btn-circle-gray-800 mr-2'>
                      <svg className='h-6 w-6' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                        <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5} d='M12 6v6m0 0v6m0-6h6m-6 0H6' />
                      </svg>
                    </button>
                  </div>
                  {data.moduleBranches.map((branch, i) =>
                    <TabPanel key={'branches-tabs-panel-' + i}>
                      <div className='divide-y-2 divide-gray-800'>
                        <Input
                          icon=
                            <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                              <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                            </svg>
                          type='number'
                          value={branch.quantity}
                          name={'quantity-' + branch.uid}
                          label='# Branches'
                          placeholder='None'
                          onChange={(e) => onChangeBranches(e.target.value, 'quantity', branch.id)}
                        />
                        <Input
                          icon=
                            <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                              <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                            </svg>
                          type='number'
                          value={branch.inverters}
                          name={'inverters-' + branch.uid}
                          label='# Inverters'
                          placeholder='None'
                          onChange={(e) => onChangeBranches(e.target.value, 'inverters', branch.id)}
                        />
                      </div>
                      <div className='p-2 pt-6'>
                        <button
                          onClick={(e) => removeBranch(branch.id)}
                          className='btn-gray-800 text-sm'>
                          Remove Branch
                        </button>
                      </div>
                    </TabPanel>
                  )}
                </Tabs>
              </div>
            }

            {data.inverterModel && data.inverterModel.type === 'string' &&
              <div className='mb-12 mx-auto max-w-lg bg-gray-900 rounded-xl'>
                <div className='p-3 pb-12 leading-tight text-sm'>
                  <span className='text-gray-600'>Design</span><br />
                  Series Types
                </div>
                <Tabs selectedTabClassName='bg-gray-900 border-2 border-primary'>
                  <div className='flex items-center pb-6 border-b-2 border-gray-800 '>
                    <TabList className='flex-1 px-2 pr-6 whitespace-nowrap text-center grid grid-cols-6 gap-1'>
                      {data.moduleSeries.map((series, i) =>
                        <Tab key={'series-tabs-' + i} className='cursor-pointer px-4 border-2 border-gray-800 hover:border-primary transition duration-150 rounded-full text-sm py-1'>S{series.id + 1}</Tab>
                      )}
                    </TabList>
                    <button
                      onClick={() => addSeries()}
                      className='btn-circle-gray-800 mr-2'>
                      <svg className='h-6 w-6' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                        <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5} d='M12 6v6m0 0v6m0-6h6m-6 0H6' />
                      </svg>
                    </button>
                  </div>
                  {data.moduleSeries.map((series, i) =>
                    <TabPanel key={'series-tabs-panel-' + i}>
                      <div className='divide-y-2 divide-gray-800'>
                        <Input
                          icon=
                            <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                              <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                            </svg>
                          type='number'
                          value={series.quantity}
                          name={'quantity-' + series.uid}
                          label='# Series'
                          placeholder='None'
                          onChange={(e) => onChangeSeries(e.target.value, 'quantity', series.id)}
                        />
                        <Input
                          icon=
                            <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                              <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                            </svg>
                          type='number'
                          value={series.modules}
                          name={'modules-' + series.uid}
                          label='# Modules'
                          placeholder='None'
                          onChange={(e) => onChangeSeries(e.target.value, 'modules', series.id)}
                        />
                      </div>
                      <div className='p-2 pt-6'>
                        <button
                          onClick={(e) => removeSeries(series.id)}
                          className='btn-gray-800 text-sm'>
                          Remove Series
                        </button>
                      </div>
                    </TabPanel>
                  )}
                </Tabs>
              </div>
            }

            <div className='mb-12 mx-auto max-w-lg bg-gray-900 rounded-xl'>
              <div className='p-3 pb-12 leading-tight text-sm'>
                <span className='text-gray-600'>Electrical</span><br />
                Components
              </div>
              <Tabs selectedTabClassName='bg-gray-900 border-2 border-primary'>
                <TabList className='px-2 pb-6 whitespace-nowrap text-center grid grid-cols-4 gap-1'>
                  <Tab className='cursor-pointer px-4 border-2 border-gray-800 hover:border-primary transition duration-150 rounded-full text-sm py-1'>Meter</Tab>
                  <Tab className='cursor-pointer px-4 border-2 border-gray-800 hover:border-primary transition duration-150 rounded-full text-sm py-1'>Service Panel</Tab>
                  <Tab className='cursor-pointer px-4 border-2 border-gray-800 hover:border-primary transition duration-150 rounded-full text-sm py-1'>Breaker</Tab>
                  <Tab className='cursor-pointer px-4 border-2 border-gray-800 hover:border-primary transition duration-150 rounded-full text-sm py-1'>Disconnect</Tab>
                  <Tab className='cursor-pointer px-4 border-2 border-gray-800 hover:border-primary transition duration-150 rounded-full text-sm py-1'>Junction</Tab>
                  <Tab className='cursor-pointer px-4 border-2 border-gray-800 hover:border-primary transition duration-150 rounded-full text-sm py-1'>Gen. Meter</Tab>
                  <Tab className='cursor-pointer px-4 border-2 border-gray-800 hover:border-primary transition duration-150 rounded-full text-sm py-1'>Combiner</Tab>
                </TabList>
                <TabPanel key='meter'>
                  <div className='divide-y-2 divide-gray-800 border-t-2 border-gray-800'>
                    <MySelect
                      icon=
                        <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z' />
                        </svg>
                      value={data.meterRating}
                      name='meterRating'
                      label='Meter Rating'
                      onChange={(e) => onChange(e, 'meterRating')}
                      options={[{
                        value: '120-240',
                        label: '120/240V'
                      }]}
                    />
                    <MySelect
                      icon=
                        <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z' />
                        </svg>
                      value={data.meterLife}
                      name='meterLife'
                      label='Meter Life'
                      onChange={(e) => onChange(e, 'meterLife')}
                      options={[{
                        value: 'new',
                        label: 'New'
                      },{
                        value: 'existing',
                        label: 'Existing'
                      }]}
                    />
                  </div>
                </TabPanel>
                <TabPanel key='service-panel'>
                  <div className='divide-y-2 divide-gray-800 border-t-2 border-gray-800'>
                    <MySelect
                      icon=
                        <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z' />
                        </svg>
                      value={data.servicePanelRating}
                      name='servicePanelRating'
                      label='Service Panel Rating'
                      onChange={(e) => onChange(e, 'servicePanelRating')}
                      options={servicePanelRatings}
                    />
                    <MySelect
                      icon=
                        <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z' />
                        </svg>
                      value={data.servicePanelLife}
                      name='servicePanelLife'
                      onChange={(e) => onChange(e, 'servicePanelLife')}
                      label='Service Panel Life'
                      options={[{
                        value: 'new',
                        label: 'New'
                      },{
                        value: 'existing',
                        label: 'Existing'
                      }]}
                    />
                  </div>
                </TabPanel>
                <TabPanel key='main-breaker'>
                  <div className='divide-y-2 divide-gray-800 border-t-2 border-gray-800'>
                    <MySelect
                      icon=
                        <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z' />
                        </svg>
                      value={data.mainBreakerRating}
                      name='mainBreakerRating'
                      label='Breaker Rating'
                      onChange={(e) => onChange(e, 'mainBreakerRating')}
                      options={mainBreakerRatings}
                    />
                    <MySelect
                      icon=
                        <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z' />
                        </svg>
                      value={data.breakerLife}
                      name='breakerLife'
                      label='Breaker Life'
                      onChange={(e) => onChange(e, 'breakerLife')}
                      options={[{
                        value: 'new',
                        label: 'New'
                      },{
                        value: 'existing',
                        label: 'Existing'
                      }]}
                    />
                  </div>
                </TabPanel>
                <TabPanel key='disconnect'>
                  <div className='divide-y-2 divide-gray-800 border-t-2 border-gray-800'>
                    <MySelect
                      icon=
                        <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z' />
                        </svg>
                      value={data.disconnectRating}
                      name='disconnectRating'
                      label='Disconnect Rating'
                      onChange={(e) => onChange(e, 'disconnectRating')}
                      options={[{
                        value: '60',
                        label: '60A'
                      },{
                        value: '100',
                        label: '100A'
                      },{
                        value: '200',
                        label: '200A'
                      }]}
                    />
                    <MySelect
                      icon=
                        <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z' />
                        </svg>
                      value={data.disconnectFuseRating}
                      name='disconnectFuseRating'
                      label='Disconnect Fuse Rating'
                      onChange={(e) => onChange(e, 'disconnectFuseRating')}
                      options={[{
                        value: '60',
                        label: '60A'
                      },{
                        value: '100',
                        label: '100A'
                      },{
                        value: '200',
                        label: '200A'
                      }]}
                    />
                    <MySelect
                      icon=
                        <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z' />
                        </svg>
                      value={data.disconnectLife}
                      name='disconnectLife'
                      label='Disconnect Life'
                      onChange={(e) => onChange(e, 'disconnectLife')}
                      options={[{
                        value: 'new',
                        label: 'New'
                      },{
                        value: 'existing',
                        label: 'Existing'
                      }]}
                    />
                  </div>
                </TabPanel>
                <TabPanel key='junction'>
                  <div className='divide-y-2 divide-gray-800 border-t-2 border-gray-800'>
                    <MySelect
                      icon=
                        <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z' />
                        </svg>
                      value={data.junctionLife}
                      name='junctionLife'
                      label='Junction Life'
                      onChange={(e) => onChange(e, 'junctionLife')}
                      options={[{
                        value: 'new',
                        label: 'New'
                      },{
                        value: 'existing',
                        label: 'Existing'
                      }]}
                    />
                  </div>
                </TabPanel>
                <TabPanel key='generation-meter'>
                  <div className='divide-y-2 divide-gray-800 border-t-2 border-gray-800'>
                    <MySelect
                      icon=
                        <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5} d='M13 10V3L4 14h7v7l9-11h-7z' />
                        </svg>
                      value={data.generationMeter}
                      name='generationMeter'
                      label='Generation Meter'
                      onChange={(e) => onChange(e, 'generationMeter')}
                      options={[{
                        value: 'generation-meter',
                        label: 'Generation Meter'
                      },{
                        value: 'no-generation-meter',
                        label: 'No Generation Meter'
                      }]}
                    />
                  </div>
                </TabPanel>
                <TabPanel key='combiner'>
                  <div className='divide-y-2 divide-gray-800 border-t-2 border-gray-800'>
                    <MySelect
                      icon=
                        <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5} d='M13 10V3L4 14h7v7l9-11h-7z' />
                        </svg>
                      value={data.combiner}
                      name='combiner'
                      label='Combiner'
                      onChange={(e) => onChange(e, 'combiner')}
                      options={[{
                        value: 'envoy-combiner',
                        label: 'Envoy Combiner'
                      },{
                        value: 'no-combiner',
                        label: 'No Combiner'
                      }]}
                    />
                  </div>
                </TabPanel>
              </Tabs>
            </div>


            <div className='mb-12 max-w-lg m-auto bg-gray-900 rounded-xl overflow-hidden flex flex-col justify-between'>
              <SingleLine data={data} />
            </div>

            {data.conduitSchedule &&
              <div className='mb-12 mx-auto max-w-lg bg-gray-900 rounded-xl'>
                <div className='p-3 pb-12 leading-tight text-sm'>
                  <span className='text-gray-600'>Electrical</span><br />
                  Conduit Schedule
                </div>
                <Tabs selectedTabClassName='bg-gray-900 border-2 border-primary'>
                  <TabList className='px-2 pb-6 whitespace-nowrap text-center grid grid-cols-6 gap-1'>
                    {[...Array(conduitScheduleLength)].map((e, i) =>
                      <Tab key={'conduit-tabs-' + i} className='cursor-pointer px-4 border-2 border-gray-800 hover:border-primary transition duration-150 rounded-full text-sm py-1'>Tag {i + 1}</Tab>
                    )}
                  </TabList>
                  {[...Array(conduitScheduleLength)].map((e, i) => {
                    const schedule = data.conduitSchedule[i]
                    return (
                      <TabPanel key={'conduit-tabs-panel-' + schedule.uid} >
                        <Tabs selectedTabClassName='text-primary bg-gray-800'>
                          <TabList className='px-3 whitespace-nowrap grid gap-1 grid-flow-col auto-cols-max border-b-2 border-gray-800'>
                            <Tab className='cursor-pointer border-2 border-gray-800 hover:text-primary border-b-0 rounded-t-xl px-4 py-2 transition duration-150 text-sm focus:outline-none'>Conduit</Tab>
                            <Tab className='cursor-pointer border-2 border-gray-800 hover:text-primary border-b-0 rounded-t-xl px-4 py-2 transition duration-150 text-sm focus:outline-none'>Conductor</Tab>
                            <Tab className='cursor-pointer border-2 border-gray-800 hover:text-primary border-b-0 rounded-t-xl px-4 py-2 transition duration-150 text-sm focus:outline-none'>Neutral</Tab>
                            <Tab className='cursor-pointer border-2 border-gray-800 hover:text-primary border-b-0 rounded-t-xl px-4 py-2 transition duration-150 text-sm focus:outline-none'>Ground</Tab>
                          </TabList>
                          <TabPanel key='conduit'>
                            <div className='divide-y-2 divide-gray-800'>
                              <MySelect
                                icon=
                                  <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                                  </svg>
                                value={schedule.size}
                                name={'size-' + schedule.uid}
                                label='Conduit Size'
                                onChange={(e) => onChangeConduitSchedule(e, 'size', schedule.uid)}
                                options={conduitSizes}
                              />
                              <MySelect
                                icon=
                                  <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z' />
                                  </svg>
                                value={schedule.life}
                                name={'life-' + schedule.uid}
                                label='Conduit Life'
                                onChange={(e) => onChangeConduitSchedule(e, 'life', schedule.uid)}
                                options={[{
                                  value: 'new',
                                  label: 'New'
                                },{
                                  value: 'existing',
                                  label: 'Existing'
                                }]}
                              />
                            </div>
                          </TabPanel>
                          <TabPanel key='conductor'>
                            <div className='divide-y-2 divide-gray-800'>
                              <Input
                                className='col-span-3'
                                type='text'
                                icon=
                                  <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                                  </svg>
                                value={schedule.conductorQuantity}
                                name={'conductorQuantity-' + schedule.uid}
                                label='Conductor Quantity'
                                placeholder='None'
                                onChange={(e) => onChangeConduitSchedule(e.target.value, 'conductorQuantity', schedule.uid)}
                              />
                              <MySelect
                                icon=
                                  <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                                  </svg>
                                className='col-span-3'
                                value={schedule.conductorSize}
                                name={'conductorSize-' + schedule.uid}
                                label='Conductor Size'
                                onChange={(e) => onChangeConduitSchedule(e, 'conductorSize', schedule.uid)}
                                options={wireSizes}
                              />
                              <MySelect
                                icon=
                                  <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                                  </svg>
                                className='col-span-5'
                                value={schedule.conductorType}
                                name={'conductorType-' + schedule.uid}
                                label='Conductor Type'
                                onChange={(e) => onChangeConduitSchedule(e, 'conductorType', schedule.uid)}
                                options={wireTypes}
                              />
                            </div>
                          </TabPanel>
                          <TabPanel key='neutral'>
                            <div className='divide-y-2 divide-gray-800'>
                              <Input
                                type='text'
                                icon=
                                  <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                                  </svg>
                                value={schedule.neutralQuantity}
                                name={'neutralQuantity-' + schedule.uid}
                                label='Neutral Quantity'
                                placeholder='None'
                                onChange={(e) => onChangeConduitSchedule(e.target.value, 'neutralQuantity', schedule.uid)}
                              />
                              <MySelect
                                icon=
                                  <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                                  </svg>
                                className='col-span-3'
                                value={schedule.neutralSize}
                                name={'neutralSize-' + schedule.uid}
                                label='Neutral Size'
                                onChange={(e) => onChangeConduitSchedule(e, 'neutralSize', schedule.uid)}
                                options={wireSizes}
                              />
                              <MySelect
                                icon=
                                  <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                                  </svg>
                                className='col-span-5'
                                value={schedule.neutralType}
                                name={'neutralType-' + schedule.uid}
                                label='Neutral Type'
                                onChange={(e) => onChangeConduitSchedule(e, 'neutralType', schedule.uid)}
                                options={wireTypes}
                              />
                            </div>
                          </TabPanel>
                          <TabPanel key='ground'>
                            <div className='divide-y-2 divide-gray-800'>
                              <Input
                                type='text'
                                icon=
                                  <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                                  </svg>
                                value={schedule.groundQuantity}
                                name={'groundQuantity-' + schedule.uid}
                                label='Ground Quantity'
                                placeholder='None'
                                onChange={(e) => onChangeConduitSchedule(e.target.value, 'groundQuantity', schedule.uid)}
                              />
                              <MySelect
                                icon=
                                  <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                                  </svg>
                                className='col-span-3'
                                value={schedule.groundSize}
                                name={'groundSize-' + schedule.uid}
                                label='Ground Size'
                                onChange={(e) => onChangeConduitSchedule(e, 'groundSize', schedule.uid)}
                                options={wireSizes}
                              />
                              <MySelect
                                icon=
                                  <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8' />
                                  </svg>
                                className='col-span-5'
                                value={schedule.groundType}
                                name={'groundType-' + schedule.uid}
                                label='Ground Type'
                                onChange={(e) => onChangeConduitSchedule(e, 'groundType', schedule.uid)}
                                options={wireTypes}
                              />
                            </div>
                          </TabPanel>
                        </Tabs>
                      </TabPanel>
                    )
                  })}
                </Tabs>
              </div>
            }

            <div className='mb-12 mx-auto max-w-lg bg-gray-900 rounded-xl'>
              <div className='p-3 pb-12 leading-tight text-sm'>
                <span className='text-gray-600'>Electrical</span><br />
                Notes
              </div>
              <Input
                type='textarea'
                className='border-t-2 border-gray-800'
                value={data.electricalNotes}
                name={'electricalNotes'}
                placeholder='Add notes.'
                onChange={(e) => onChange(e.target.value, 'electricalNotes')}
              />
            </div>

            <div className='mb-12 mx-auto max-w-lg bg-gray-900 rounded-xl'>
              <div className='p-3 pb-12 leading-tight text-sm'>
                <span className='text-gray-600'>Design</span><br />
                Diagrams
              </div>
              <Tabs selectedTabClassName='bg-gray-900 border-2 border-primary'>
                <TabList className='px-2 pb-6 whitespace-nowrap text-center grid grid-cols-2 gap-1'>
                  <Tab className='cursor-pointer px-4 border-2 border-gray-800 hover:border-primary transition duration-150 rounded-full text-sm py-1'>Site Plan</Tab>
                  {data.mountingType === 'Ground Mount' && <Tab className='cursor-pointer px-4 border-2 border-gray-800 hover:border-primary transition duration-150 rounded-full text-sm py-1'>Plot Lines</Tab>}
                  <Tab className='cursor-pointer px-4 border-2 border-gray-800 hover:border-primary transition duration-150 rounded-full text-sm py-1'>Mounting Detail</Tab>
                  {data.mountingType === 'Roof Mount' && <Tab className='cursor-pointer px-4 border-2 border-gray-800 hover:border-primary transition duration-150 rounded-full text-sm py-1'>Roof Frame Detail</Tab>}
                  <Tab className='cursor-pointer px-4 border-2 border-gray-800 hover:border-primary transition duration-150 rounded-full text-sm py-1'>Warning Placard</Tab>
                </TabList>
                <TabPanel>
                  <div className='p-3'>
                    <UploadDiagram data={data} fileName='diagramSitePlan' onChange={onChange} />
                  </div>
                </TabPanel>
                {data.mountingType === 'Ground Mount' &&
                  <TabPanel>
                    <div className='p-3'>
                      <UploadDiagram data={data} fileName='diagramPlotLines' onChange={onChange} />
                    </div>
                  </TabPanel>
                }
                <TabPanel>
                  <div className='p-3'>
                    <UploadDiagram data={data} fileName='diagramMountingDetail' onChange={onChange} />
                  </div>
                </TabPanel>
                {data.mountingType === 'Roof Mount' &&
                  <TabPanel>
                    <div className='p-3'>
                      <UploadDiagram data={data} fileName='diagramRoofFrameDetail' onChange={onChange} />
                    </div>
                  </TabPanel>
                }
                <TabPanel>
                  <div className='p-3'>
                    <UploadDiagram data={data} fileName='diagramWarningPlan' onChange={onChange} />
                  </div>
                </TabPanel>
              </Tabs>
            </div>

            <button
              className='btn-gray block m-auto mt-32'
              onClick={onRemove}>
              Delete Plans
            </button>

          </div>
        }
      </main>
    </Layout>
  )
}
