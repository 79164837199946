import { useAuth, signIn } from 'lib/firebase/use-auth'

import { useState } from 'react'

export default function Index() {
  useAuth()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  async function submit() {
    if(!email || !password) {
      setError(true)
    } else {
      setLoading(true)
      const error = await signIn(email, password)
      if(error) {
        setLoading(false)
        setError(true)
      }
    }
  }

  return (
    <div className='min-h-screen flex flex-col'>
      {loading &&
        <div className='m-auto bg-gray-900 rounded-xl px-12 py-6 bg'>
          Signing in!
        </div>
      }
      {!loading &&
        <form onSubmit={submit} className='m-auto w-full max-w-sm p-4'>
          <div className={'group border-2 border-current block mx-auto mb-4 h-20 w-20 duration-150 transform hover:scale-105 flex rounded-xl bg-gray-900 text-primary'} to='/'>
            <span className='flex items-center justify-center absolute top-0 left-0 h-full w-full text-3xl block transform scale-100 opacity-100 duration-150 group-hover:scale-0 group-hover:opacity-0'>
              <svg xmlns='http://www.w3.org/2000/svg' className='h-8 w-8' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2' />
              </svg>
            </span>
            <span className='flex items-center justify-center absolute top-0 left-0 h-full w-full block transform scale-0 opacity-0 duration-150 group-hover:scale-100 group-hover:opacity-100'>Plans</span>
          </div>
          <input
            className='input mb-2'
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)} />
          <input
            className='input'
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)} />
          {error &&
            <div className='mt-6 text-center leading-tight'>Please check that you have entered your credentials correctly.</div>
          }
          <button type='submit' className='btn-primary my-6 mx-auto block'>Sign In</button>
          <div className='text-center text-sm text-gray-600'>&copy; {new Date().getFullYear()} RisingSun. All rights reserved.</div>
        </form>
      }
    </div>
  )
}
