import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import ScrollToTop from 'components/scroll-to-top'

import SignIn from 'pages/auth/sign-in'
import Dashboard from 'pages/dashboard/index'
import Plans from 'pages/plans/index'
import PlansId from 'pages/plans/id'

export default function Index() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path='/signin'>
          <SignIn />
        </Route>
        <Route exact path='/'>
          <Dashboard />
        </Route>
        <Route exact path='/plans'>
          <Plans />
        </Route>
        <Route path='/plans/:id'>
          <PlansId />
        </Route>
      </Switch>
    </Router>
  )
}
