import { useAuth } from 'lib/firebase/use-auth'
import { db } from 'lib/firebase/firebase'
import useLocalStorage from 'lib/useLocalStorage'

import { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'

import Modal from 'react-modal'

import Layout from 'components/layout'
import Address from 'components/plans/google-address'
import Map from 'components/plans/leaflet-map'
import Input from 'components/input'
import MySelect from 'components/select'

import { useDebouncedCallback } from 'use-debounce'

const categories = []
let unsubscribe = () => {}

export default function Index() {
  useAuth()

  const [filter, setFilter] = useLocalStorage(process.env.REACT_APP_LOCAL_STORAGE + 'filters-plans', categories[0])
  const [view, setView] = useLocalStorage(process.env.REACT_APP_LOCAL_STORAGE + 'views-plans', 'list')
  const [search, setSearch] = useState(false)
  const [plans, setPlans] = useState([])
  const [showAdd, setShowAdd] = useState(false)

  useEffect(() => {
    Modal.setAppElement('body')
    return () => unsubscribe()
  }, [])

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  useEffect(() => {
    if(search) {
      debouncedFetchData()
    } else if(search !== false) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const debouncedFetchData = useDebouncedCallback(() => {
    fetchData()
  }, 1000)

  function fetchData() {
    console.log('fetch')
    unsubscribe()
    if(search && search !== '') {
      if(filter) {
        // with search & filter
        unsubscribe = db.collection('plans')
        .where('category.value', '==', filter.value)
        .where('keywords', 'array-contains', search.toLowerCase())
        .orderBy('createdAt', 'asc')
        .limit(21)
        .onSnapshot(snapshot => {
          const plans = []
          snapshot.forEach(doc => plans.push(doc))
          setPlans(plans)
        })
      } else {
        // with search & no filter
        unsubscribe = db.collection('plans')
        .where('keywords', 'array-contains', search.toLowerCase())
        .orderBy('createdAt', 'asc')
        .limit(21)
        .onSnapshot(snapshot => {
          const plans = []
          snapshot.forEach(doc => plans.push(doc))
          setPlans(plans)
        })
      }
    } else {
      if(filter) {
        // with filter & no search
        unsubscribe = db.collection('plans')
        .where('category.value', '==', filter.value)
        .orderBy('createdAt', 'asc')
        .limit(21)
        .onSnapshot(snapshot => {
          const plans = []
          snapshot.forEach(doc => plans.push(doc))
          setPlans(plans)
        })
      } else {
        // with no filter & no search
        unsubscribe = db.collection('plans')
        .orderBy('createdAt', 'asc')
        .limit(21)
        .onSnapshot(snapshot => {
          const plans = []
          snapshot.forEach(doc => plans.push(doc))
          setPlans(plans)
        })
      }
    }
  }

  return (
    <Layout>
      <main>
        {view === 'list' && <List plans={plans} filter={filter} setFilter={setFilter} setView={setView} search={search} setSearch={setSearch} />}
        {view === 'grid' && <Grid plans={plans} filter={filter} setFilter={setFilter} setView={setView} search={search} setSearch={setSearch} />}
        <div className='fixed z-10 bottom-0 right-0 p-6'>
          <button className='btn-circle-primary-lg' onClick={() => setShowAdd(true)}>
            <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
              <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M12 6v6m0 0v6m0-6h6m-6 0H6' />
            </svg>
          </button>
        </div>
        <AddPlans isOpen={showAdd} close={() => setShowAdd(false)} />
      </main>
    </Layout>
  )
}

function Filter({ filter, setFilter, setView, search, setSearch }) {
  return (
    <div className='mb-6 flex w-full justify-between'>
      <div className='flex'>
        <ul className='grid grid-cols-2 gap-2'>
          <li>
            <Input
              type='text'
              icon=
              <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z' />
              </svg>
              size='sm'
              value={search}
              name='search'
              placeholder='Search'
              onChange={(e) => setSearch(e.target.value)}
            />
          </li>
          <li>
            <MySelect
              size='sm'
              value={filter}
              onChange={(e) => setFilter(e)}
              options={categories}
              placeholder='Filter'
            />
          </li>
        </ul>
        {filter &&
          <button className='px-2 text-gray-600 hover:text-primary transition duration-150 focus:outline-none' onClick={() => setFilter(null)}>
            <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
              <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M6 18L18 6M6 6l12 12' />
            </svg>
          </button>
        }
      </div>
      <div className='flex'>
        <button className='btn-circle-gray mr-2' onClick={() => setView('list')}>
          <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5} d='M4 6h16M4 10h16M4 14h16M4 18h16' />
          </svg>
        </button>
        <button className='btn-circle-gray' onClick={() => setView('grid')}>
          <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5} d='M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z' />
          </svg>
        </button>
      </div>
    </div>
  )
}

function List({ plans, filter, setFilter, setView, search, setSearch }) {
  return (
    <div className='p-6 py-32 w-full max-w-4xl m-auto'>
      <Filter filter={filter} setFilter={setFilter} setView={setView} search={search} setSearch={setSearch} />
      <ul className='divide-y-2 divide-gray-900'>
        {plans.map((doc) =>
          <li className='fade-in' key={'list-'+doc.id}>
            <Link
              to={'/plans/'+doc.id}
              className='group py-2 block w-full text-left flex items-center focus:outline-none'>
              <span className='pr-4'>
                <span className='block h-12 w-12 rounded-full overflow-hidden bg-gray-900'>
                  {doc.data().mapAerialStatic && <img className='h-full w-full object-cover' alt={doc.data().name + ' featured image.'} src={doc.data().mapAerialStatic} />}
                </span>
              </span>
              <span className='group-hover:text-primary transition duration-150 flex-1 truncate pr-4'>{doc.data().name ? doc.data().name : <span className='text-gray-600'>No Name</span>}</span>
              <span className='flex-1 truncate text-gray-600 text-right'>{doc.data().location.address}</span>
            </Link>
          </li>
        )}
      </ul>
    </div>
  )
}

function Grid({ plans, filter, setFilter, setView, search, setSearch }) {
  return (
    <div className='p-6 py-32 w-full max-w-4xl m-auto'>
      <Filter filter={filter} setFilter={setFilter} setView={setView} search={search} setSearch={setSearch} />
      <ul className='grid grid-cols-2 sm:grid-cols-4 gap-6'>
        {plans.map((doc) =>
          <li className='fade-in' key={'grid-'+doc.id}>
            <Link
              to={'/plans/'+doc.id}
              className='leading-tight border-2 border-gray-900 hover:border-primary transition duration-150 rounded-xl overflow-hidden block w-full text-left focus:outline-none'>
              {!doc.data().mapAerialStatic && <span className='block aspect-ratio-16/9 bg-gray-900' />}
              {doc.data().mapAerialStatic && <img alt={doc.data().name + ' featured image.'} src={doc.data().mapAerialStatic} />}
              <span className='block px-4 pt-2 truncate'>{doc.data().name ? doc.data().name : <span className='text-gray-600'>No Name</span>}</span>
              <span className='block px-4 pb-2 truncate text-gray-600'>{doc.data().location.address}</span>
            </Link>
          </li>
        )}
      </ul>
    </div>
  )
}

function AddPlans({ isOpen, close }) {
  const auth = useAuth()
  const history = useHistory()

  const [isCreating, setIsCreating] = useState(false)
  const [location, setLocation] = useState(false)
  const [mapState, setMapState] = useState(false)

  const onSave = async () => {
    setIsCreating(true)

    const components = {}
    location.address_components.forEach(component => {
      if(component.types.includes('locality')) components.city = { value: component.short_name, label:  component.long_name }
      if(component.types.includes('administrative_area_level_1')) components.state = { value: component.short_name, label:  component.long_name }
      if(component.types.includes('administrative_area_level_2')) components.county = { value: component.short_name, label:  component.long_name }
      if(component.types.includes('neighborhood')) components.neighborhood = { value: component.short_name, label:  component.long_name }
      if(component.types.includes('postal_code')) components.zip = component.long_name
      if(component.types.includes('country')) components.country = { value: component.short_name, label:  component.long_name }
    })

    const newPlan = {
      createdAt: new Date(),
      createdBy: auth.id,
      location: {
        ...components,
        placeId: location.place_id,
        address: location.formatted_address,
        lat: location.geometry.location.lat(),
        lng: location.geometry.location.lng(),
      },
      mapVicinityState: mapState,
      mapAerialState: mapState,
    }

    const doc = await db.collection('plans').add({ createdAt: new Date(), ...newPlan })
    close()
    history.push('/plans/' + doc.id)
  }

  return (
    <Modal
      isOpen={isOpen}
      className='m-auto focus:outline-none w-full max-w-md'
      overlayClassName='bg-black top-0 left-0 fixed z-50 h-screen w-screen flex'>
      <div>
        {isCreating && 'Creating your plan!'}
        {!isCreating &&
          <>
            <Address onChange={(e) => {
              setLocation(e)
              setMapState({
                lat: e.geometry.location.lat(),
                lng: e.geometry.location.lng(),
                zoom: 19
              })
            }} />
            {mapState &&
              <>
                <Map
                  lat={mapState.lat}
                  lng={mapState.lng}
                  zoom={mapState.zoom}
                  onChange={(state) => setMapState(state)} />
                <button
                  className='block btn-primary m-auto mt-6'
                  onClick={() => onSave()}>
                  Create Plans
                </button>
              </>
            }
          </>
        }
      </div>
      <button
        className='btn-gray fixed top-0 right-0 m-6'
        onClick={close}>
        Close
      </button>
    </Modal>
  )
}
