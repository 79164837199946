export const electricalCodes = [
  {
    value: 'nec-2020',
    label: 'NEC 2020',
    imgSrc: '/img/codes/nec/nec-2020.jpg'
  },{
    value: 'nec-2017',
    label: 'NEC 2017',
    imgSrc: '/img/codes/nec/nec-2017.jpg'
  },{
    value: 'nec-2014',
    label: 'NEC 2014',
    imgSrc: '/img/codes/nec/nec-2014.jpg'
  },{
    value: 'nec-2011',
    label: 'NEC 2011',
    imgSrc: '/img/codes/nec/nec-2011.jpg'
  },
]

export const fireCodes = [
  {
    value: 'ifc-2018',
    label: 'IFC 2018'
  }
]

export const buildingCodes = [
  {
    value: 'ibc-2015',
    label: 'IBC 2015'
  }
]

export const residentialCodes = [
  {
    value: 'irc-2018',
    label: 'IRC 2018'
  }
]
