import { useState, useEffect } from 'react'

import ReactFlow, { ReactFlowProvider } from 'react-flow-renderer'

import { generateLayout, nodeTypes } from './single-line-layout-generator.js'

export default function SingleLine({ data }) {
  const [flow, setFlow] = useState(false)
  const [elements, setElements] = useState([])

  useEffect(() => {
    if(flow) {
      const newElements = generateLayout(data)
      setElements(newElements)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if(flow) setTimeout(() => flow.setTransform({ x: 15, y: 60, zoom: .6 }), 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elements])

  const onLoad = (reactFlowInstance) => {
    setFlow(reactFlowInstance)
    reactFlowInstance.setTransform({ x: 15, y: 60, zoom: .6 })
  }

  return (
    <div id='flow-container'>
      <div id='single-line-flow-chart' style={{height: 220}} className='w-full bg-white rounded-xl overflow-hidden'>
        <ReactFlowProvider>
          <ReactFlow
            nodeTypes={nodeTypes}
            elements={elements}
            onLoad={onLoad}
            snapToGrid={true}
            snapGrid={[10, 10]}
            // paneMoveable={false}
            // zoomOnScroll={false}
            // zoomOnPinch={false}
            // zoomOnDoubleClick={false}
            elementsSelectable={false}
            nodesDraggable={false}>
          </ReactFlow>
        </ReactFlowProvider>
      </div>
    </div>
  )
}
