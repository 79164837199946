export const roofTypeOptions = [
  {
    value: 'composition-shingle',
    label: 'Composition Shingle',
  },{
    value: 'metal-corrugated',
    label: 'Metal Corrugated',
  },{
    value: 'metal-standing-seam',
    label: 'Metal Standing Seam',
  },{
    value: 'tpo',
    label: 'TPO',
  },{
    value: 'epdm',
    label: 'EPDM',
  },{
    value: 'rolled-shingle',
    label: 'Rolled Shingle',
  },{
    value: 'tile-roof',
    label: 'Tile Roof',
  }
]

export const roofExposureOptions = [
  {
    value: 'attic',
    label: 'Attic',
  },{
    value: 'vaulted',
    label: 'Vaulted',
  }
]
