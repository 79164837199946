import React from 'react'
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer'

Font.registerHyphenationCallback(word => (
  [word]
))

const styles = StyleSheet.create({
  page: {
    padding: 10
  },
  frame: {
    flexDirection: 'row',
    borderColor: 'black',
    borderWidth: 1.5,
    borderStyle: 'solid',
    height: '100%'
  },
  frameLeft: {
    flexDirection: 'column',
    justifyContent: 'between',
    width: 180,
    borderRightColor: 'black',
    borderRightWidth: 1.5,
    borderRightStyle: 'solid'
  },
  frameRight: {
    flexDirection: 'column',
    justifyContent: 'between',
    width: 180,
    borderLeftColor: 'black',
    borderLeftWidth: 1.5,
    borderLeftStyle: 'solid'
  },
  frameMiddle: {
    flex: 1,
    flexDirection: 'column',
  },
  textTitle: {
    padding: 2,
    fontSize: 19,
    lineHeight: 1,
    fontFamily: 'Helvetica-Bold',
    textTransform: 'uppercase'
  },
  textIndexTitle: {
    padding: 2,
    paddingBottom: 4,
    marginTop: 20,
    fontSize: 15,
    fontFamily: 'Helvetica-Bold',
    lineHeight: 1,
    borderBottomColor: 'black',
    borderBottomWidth: 1.5,
    borderBottomStyle: 'solid',
    textTransform: 'uppercase'
  },
  textDate: {
    padding: 2,
    fontSize: 12,
    lineHeight: 1.1,
    fontFamily: 'Helvetica',
    textTransform: 'uppercase'
  },
  textDisplay: {
    padding: 2,
    marginTop: 20,
    fontSize: 10,
    lineHeight: 1,
    borderBottomColor: 'black',
    borderBottomWidth: 1.5,
    borderBottomStyle: 'solid',
    width: '100%',
    fontFamily: 'Helvetica-Bold',
    textTransform: 'uppercase'
  },
  textBody: {
    fontSize: 8,
    lineHeight: 1.16,
    fontFamily: 'Helvetica',
    textTransform: 'uppercase'
  },
  textBodyBold: {
    fontSize: 8,
    lineHeight: 1.1,
    fontFamily: 'Helvetica-Bold',
    textTransform: 'uppercase'
  },
  textWarningTitle: {
    fontSize: 28,
    marginTop: 20,
    lineHeight: 1.2,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  textWarning: {
    fontSize: 19,
    margin: 20,
    lineHeight: 1.1,
    fontFamily: 'Helvetica',
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  pad: {
    padding: 2
  },
  panelFrame: {
    margin: 10,
    borderColor: 'black',
    borderWidth: 1.5,
    borderStyle: 'solid'
  },
  map: {
    width: '100%',
    borderBottomColor: 'black',
    borderBottomWidth: 1.5,
    borderBottomStyle: 'solid'
  },
  planFrame: {
    flex: 1,
    width: '100%',
    height: 0,
    position: 'relative'
  },
  imageStretch: {
    height: '100%',
    width: '100%',
    objectFit: 'scale-down'
  },
  logo: {
    padding: 2,
    marginTop: 20,
    width: 120
  },
  elecFrame: {
    flexDirection: 'column',
    justifyContent: 'between',
    height: '100%'
  },
  specFrame: {
    flexDirection: 'row',
  },
  specBox: {
    flex: 1,
    paddingRight: 10,
  },
  border: {
    borderWidth: 1.5,
    borderColor: 'black',
    borderStyle: 'solid',
  },
  tableBorder: {
    flexDirection: 'row',
    borderBottomWidth: 1.5,
    borderBottomColor: 'black',
    borderBottomStyle: 'solid',
  },
  table: {
    flexDirection: 'row'
  },
  cell: {
    flex: 1
  },
  wireCalcDescript: {
    width: 280,
    borderLeftWidth: 1.5,
    borderLeftColor: 'black',
    borderLeftStyle: 'solid',
  },
  placardFrame: {
    width: '100%',
    flexDirection: 'row',
  },
  warningPlacard: {
    flex: 1,
  },
  warningDiagram: {
    flex: 1,
    padding: 20
  },
  warningDiagramFrame: {
    borderWidth: 1.5,
    borderColor: 'black',
    borderStyle: 'solid',
    marginBottom: 5
  },
  infoFrame: {
    flexDirection: 'column',
    justifyContent: 'between',
    height: '100%'
  },
})

export default function PdfDocument({ data, singleLine }) {
  const today = new Date()
  const date = (today.getMonth() + 1) + '/' + today.getDate() + '/' + today.getFullYear()

  function SiteCatalogue() {
    return (
      <>
        <Text style={styles.textDisplay}>Site Catalogue</Text>
        <View style={[styles.tableBorder, styles.pad]}>
          <View style={{ width: '30' }}>
            <Text style={styles.textBody}>T-1</Text>
          </View>
          <View style={styles.cell}>
            <Text style={styles.textBody}>Cover Page</Text>
          </View>
        </View>
        {data.mountingType === 'Ground Mount' &&
          <View style={[styles.tableBorder, styles.pad]}>
            <View style={{ width: '30' }}>
              <Text style={styles.textBody}>T-2</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.textBody}>Cover Page</Text>
            </View>
          </View>
        }
        <View style={[styles.tableBorder, styles.pad]}>
          <View style={{ width: '30' }}>
            <Text style={styles.textBody}>M-1</Text>
          </View>
          <View style={styles.cell}>
            <Text style={styles.textBody}>Mounting Detail</Text>
          </View>
        </View>
        <View style={[styles.tableBorder, styles.pad]}>
          <View style={{ width: '30' }}>
            <Text style={styles.textBody}>M-2</Text>
          </View>
          <View style={styles.cell}>
            <Text style={styles.textBody}>Mounting Detail</Text>
          </View>
        </View>
        <View style={[styles.tableBorder, styles.pad]}>
          <View style={{ width: '30' }}>
            <Text style={styles.textBody}>M-3</Text>
          </View>
          <View style={styles.cell}>
            <Text style={styles.textBody}>Structural Detail</Text>
          </View>
        </View>
        <View style={[styles.tableBorder, styles.pad]}>
          <View style={{ width: '30' }}>
            <Text style={styles.textBody}>E-1</Text>
          </View>
          <View style={styles.cell}>
            <Text style={styles.textBody}>Single Line Diagram</Text>
          </View>
        </View>
        <View style={[styles.tableBorder, styles.pad]}>
          <View style={{ width: '30' }}>
            <Text style={styles.textBody}>PL-1</Text>
          </View>
          <View style={styles.cell}>
            <Text style={styles.textBody}>Warning Placards</Text>
          </View>
        </View>
        <View style={[styles.tableBorder, styles.pad]}>
          <View style={{ width: '30' }}>
            <Text style={styles.textBody}>SS</Text>
          </View>
          <View style={styles.cell}>
            <Text style={styles.textBody}>Spec Sheets</Text>
          </View>
        </View>
      </>
    )
  }

  function ScopeOfWork() {
    return (
      <>
        <Text style={styles.textDisplay}>Scope of Work</Text>
        <View style={styles.pad}>
          {data.moduleModel && data.moduleQuantity && data.inverterModel && data.inverterQuantity &&
            <Text style={styles.textBody}>System Size: {data.moduleQuantity * data.moduleModel.watts / 1000}kW DC, {data.inverterQuantity * data.inverterModel.maxOutputCurrent * 240 / 1000}kW AC</Text>
          }
          {data.moduleModel &&
            <Text style={styles.textBody}>Modules: ({data.moduleQuantity}) {data.moduleModel.label}</Text>
          }
          {data.inverterModel && data.inverterQuantity &&
            <Text style={styles.textBody}>Inverters: ({data.inverterQuantity}) {data.inverterModel.label}</Text>
          }
          {data.batteryModel && data.batteryQuantity &&
            <Text style={styles.textBody}>Batteries: ({data.batteryQuantity}) {data.batteryModel.label}</Text>
          }
        </View>
      </>
    )
  }

  function ApplicableCodes() {
    return (
      <>
        <Text style={styles.textDisplay}>Applicable Codes</Text>
        <View style={styles.pad}>
          {data.codeElectrical && <Text style={styles.textBody}>Electric Code: {data.codeElectrical.label}</Text>}
          {data.codeFire && <Text style={styles.textBody}>Fire Code: {data.codeFire.label}</Text>}
          {data.codeBuilding && <Text style={styles.textBody}>Building Code: {data.codeBuilding.label}</Text>}
          {data.codeResidential && <Text style={styles.textBody}>Residential Code: {data.codeResidential.label}</Text>}
        </View>
      </>
    )
  }

  function GeneralNotes() {
    return (
      <>
        <Text style={styles.textDisplay}>General Notes</Text>
        <View style={styles.pad}>
          <Text style={styles.textBody}>1. Modules are listed under ul 1703 and conform to the standards.</Text>
          <Text style={styles.textBody}>2. Inverters are listed under ul 1741 and conform to the standards.</Text>
          <Text style={styles.textBody}>3. Drawings are diagrammatic, indicating general arrangement of the pv system and the actual site condition might vary.</Text>
          <Text style={styles.textBody}>4. Working clearances around the new pv electrical equipment will be maintained in accordance with nec 110.26.</Text>
          <Text style={styles.textBody}>5. All ground wiring connected to the main service grounding in main service panel/service equipment.</Text>
          <Text style={styles.textBody}>6. All conductors shall be 600v, 75°c standard copper unless otherwise noted.</Text>
          <Text style={styles.textBody}>7. When required, a ladder shall be in place for inspection in compliance with osha regulations.</Text>
          <Text style={styles.textBody}>8. The system will not be interconnected by the contractor until approval from the local jurisdiction and/or the utility.</Text>
          <Text style={styles.textBody}>9. Roof access point shall be located in areas that do not require the placement of ground ladders over openings such as windows or doors, and located at strong points of building construction where the access point does not conflict with overhead obstructions such as trees, wires or signs.</Text>
          <Text style={styles.textBody}>10. Pv array combiner/junction box provides transition from array wiring to conduit wiring.</Text>
        </View>
      </>
    )
  }

  function VicinityMap() {
    return (
      <>
        <Text style={styles.textDisplay}>Vicinity Map</Text>
        <Image style={styles.map} src={data.mapVicinityStatic} />
      </>
    )
  }

  function InstallationNotes() {
    return (
      <>
        <Text style={styles.textDisplay}>Installation Notes</Text>
        {data.mountingType === 'Roof Mount' &&
          <View style={styles.pad}>
            <Text style={styles.textBody}>1. Structural roof member locations are estimated and should be located and verified by the contractor when lag bolt penetration or mechanical attachment to the structure is required.</Text>
            <Text style={styles.textBody}>2. Rooftop penetrations for solar racking will be completed and sealed with approved sealant per code by a licensed contractor.</Text>
            <Text style={styles.textBody}>3. Lags must have a minimum 2.5” thread embedment into the structural member.</Text>
            <Text style={styles.textBody}>4. All pv racking attachments shall be staggered by row between the roof framing members as necessary.</Text>
            <Text style={styles.textBody}>5. Roof mounted standard rail requires one thermal expansion gap for every run of rail greater than 40’.</Text>
            <Text style={styles.textBody}>6. All conductors and conduits on the roof shall be minimum 2.5” above the roof surface (including cables underneath modules and racking).</Text>
            <Text style={styles.textBody}>7. The pv installation shall not obstruct any plumbing, mechanical or building roof vents.</Text>
          </View>
        }
        {data.mountingType === 'Ground Mount' &&
          <View style={styles.pad}>
            <Text style={styles.textBody}>1. Solar photovoltaic system to be installed on the ground.</Text>
            <Text style={styles.textBody}>2. This project has been designed in compliance with the IBC 2018 to withstand a basic wind speed of 105 MPH (3 second gus). Wind Exposure C.</Text>
          </View>
        }
      </>
    )
  }

  function RoofAccessPS() {
    return (
      <>
        <Text style={styles.textDisplay}>Roof Access Pathways{'\n'}&amp; Setbacks</Text>
        <View style={styles.pad}>
          <Text style={styles.textBodyBold}>Ifc 605.11.1.2.2 hip roof layouts</Text>
          <Text style={styles.textBody}>Panels and modules installed on group r-3 buildings with hip roof layouts shall be located in a manner that provides two, 3-foot wide access pathways from the eave to the ridge on each roof slope where panels and modules are located. The access pathway shall be at a location on the building capable of supporting the fire fighters accessing the roof.</Text>
          <Text style={styles.textBodyBold}>Ifc 605.11.1.2.3 single-ridged roofs</Text>
          <Text style={styles.textBody}>Panels and modules installed on group r-3 buildings with a single ridge shall be located in a manner that provides two, 3-foot wide access pathways from the eave to the ridge on each roof slope where panels and modules are located.</Text>
          <Text style={styles.textBodyBold}>Ifc 605.11.1.2.4 roofs with hips and valleys</Text>
          <Text style={styles.textBody}>Panels and modules installed on group r-3 buildings with roof hips and valleys shall not be located closer than 18 inches to a hip or a valley where panels/modules are to be placed on both sides of a hip or valley. Where panels are to be located on only one side of a hip or valley that is of equal length, the panels shall be permitted to be placed directly adjacent to the hip or valley.</Text>
          <Text style={styles.textBodyBold}>Exception</Text>
          <Text style={styles.textBody}>These requirement shall not apply to roofs with slopes of two units vertical in 12 units horizontal (2:12) or less.</Text>
        </View>
      </>
    )
  }

  function AerialView() {
    return (
      <>
        <Text style={styles.textDisplay}>Aerial View</Text>
        <Image style={styles.map} src={data.mapAerialStatic} />
      </>
    )
  }

  function SiteInformation() {
    const siStyles = StyleSheet.create({
      srNo: {
        width: 50
      },
      azimuth: {
        width: 50
      },
      pitch: {
        width: 50
      },
      modules: {
        width: 50
      },
      area: {
        width: 50
      },
      roofExposure: {
        width: 70
      },
      frameType: {
        width: 70
      },
      frameSize: {
        width: 70
      },
      frameSpacing: {
        width: 70
      },
      railSpan: {
        width: 70
      },
      overHang: {
        width: 70
      }
    })
    function Header() {
      return (
        <View style={styles.tableBorder}>
          <View style={siStyles.srNo}>
            <View style={styles.pad}>
              <Text style={styles.textBodyBold}>Sr. No.</Text>
            </View>
          </View>
          <View style={siStyles.azimuth}>
            <View style={styles.pad}>
              <Text style={styles.textBodyBold}>Azimuth</Text>
            </View>
          </View>
          <View style={siStyles.pitch}>
            <View style={styles.pad}>
              <Text style={styles.textBodyBold}>Pitch</Text>
            </View>
          </View>
          <View style={siStyles.modules}>
            <View style={styles.pad}>
              <Text style={styles.textBodyBold}>Modules</Text>
            </View>
          </View>
          <View style={styles.cell}>
            <View style={styles.pad}>
              <Text style={styles.textBodyBold}>Attachment</Text>
            </View>
          </View>
          {data.mountingType === 'Roof Mount' &&
            <>
              <View style={styles.cell}>
                <View style={styles.pad}>
                  <Text style={styles.textBodyBold}>Roof Type</Text>
                </View>
              </View>
              <View style={siStyles.area}>
                <View style={styles.pad}>
                  <Text style={styles.textBodyBold}>Area</Text>
                </View>
              </View>
              <View style={siStyles.roofExposure}>
                <View style={styles.pad}>
                  <Text style={styles.textBodyBold}>Roof Exposure</Text>
                </View>
              </View>
              <View style={siStyles.frameType}>
                <View style={styles.pad}>
                  <Text style={styles.textBodyBold}>Frame Type</Text>
                </View>
              </View>
              <View style={siStyles.frameSize}>
                <View style={styles.pad}>
                  <Text style={styles.textBodyBold}>Frame Size</Text>
                </View>
              </View>
              <View style={siStyles.frameSpacing}>
                <View style={styles.pad}>
                  <Text style={styles.textBodyBold}>Frame Spacing</Text>
                </View>
              </View>
              <View style={siStyles.railSpan}>
                <View style={styles.pad}>
                  <Text style={styles.textBodyBold}>Rail Span</Text>
                </View>
              </View>
              <View style={siStyles.overHang}>
                <View style={styles.pad}>
                  <Text style={styles.textBodyBold}>Over Hang</Text>
                </View>
              </View>
            </>
          }
        </View>
      )
    }

    function Row({ array }) {
      return (
        <View style={styles.tableBorder}>
          <View style={siStyles.srNo}>
            <View style={styles.pad}>
              <Text style={styles.textBody}>MP-{('0' + (array.id + 1)).slice(-2)}</Text>
            </View>
          </View>
          <View style={siStyles.azimuth}>
            <View style={styles.pad}>
              <Text style={styles.textBody}>{array.azimuth}&deg;</Text>
            </View>
          </View>
          <View style={siStyles.pitch}>
            <View style={styles.pad}>
              <Text style={styles.textBody}>{array.pitch}&deg;</Text>
            </View>
          </View>
          <View style={siStyles.modules}>
            <View style={styles.pad}>
              <Text style={styles.textBody}>{array.moduleCount}</Text>
            </View>
          </View>
          {data.mountingType === 'Roof Mount' &&
            <>
              <View style={styles.cell}>
                <View style={styles.pad}>
                  <Text style={styles.textBody}>{array.attachment && array.attachment.label}</Text>
                </View>
              </View>
              <View style={styles.cell}>
                <View style={styles.pad}>
                  <Text style={styles.textBody}>{array.roofType && array.roofType.label}</Text>
                </View>
              </View>
              <View style={siStyles.area}>
                <View style={styles.pad}>
                  <Text style={styles.textBody}>{array.area} ft&sup2;</Text>
                </View>
              </View>
              <View style={siStyles.roofExposure}>
                <View style={styles.pad}>
                  <Text style={styles.textBody}>{array.roofExposure && array.roofExposure.label}</Text>
                </View>
              </View>
              <View style={siStyles.frameType}>
                <View style={styles.pad}>
                  <Text style={styles.textBody}>{array.frameType}</Text>
                </View>
              </View>
              <View style={siStyles.frameSize}>
                <View style={styles.pad}>
                  <Text style={styles.textBody}>{array.frameSize}</Text>
                </View>
              </View>
              <View style={siStyles.frameSpacing}>
                <View style={styles.pad}>
                  <Text style={styles.textBody}>{array.frameSpacing}</Text>
                </View>
              </View>
              <View style={siStyles.railSpan}>
                <View style={styles.pad}>
                  <Text style={styles.textBody}>{array.railSpan}</Text>
                </View>
              </View>
              <View style={siStyles.overHang}>
                <View style={styles.pad}>
                  <Text style={styles.textBody}>{array.overHang}</Text>
                </View>
              </View>
            </>
          }
        </View>
      )
    }

    return (
      <>
        <Text style={styles.textDisplay}>Site Information &mdash; Wind Speed: {data.windSpeed || '0'}mph and Snow Load {data.snowLoad || '0'}psf</Text>
        <Header />
        {data.moduleArrays && data.moduleArrays.map(array => <Row key={'array-1-' + array.id} array={array} />)}
      </>
    )
  }

  function SiteInformationGround() {
    function Header() {
      return (
        <View style={styles.tableBorder}>
          <View style={styles.cell}>
            <View style={styles.pad}>
              <Text style={styles.textBodyBold}>Sr. No.</Text>
            </View>
          </View>
          <View style={styles.cell}>
            <View style={styles.pad}>
              <Text style={styles.textBodyBold}>Azimuth</Text>
            </View>
          </View>
          <View style={styles.cell}>
            <View style={styles.pad}>
              <Text style={styles.textBodyBold}>Pitch</Text>
            </View>
          </View>
          <View style={styles.cell}>
            <View style={styles.pad}>
              <Text style={styles.textBodyBold}>Modules</Text>
            </View>
          </View>
        </View>
      )
    }

    function Row({ array }) {
      return (
        <View style={styles.tableBorder}>
          <View style={styles.cell}>
            <View style={styles.pad}>
              <Text style={styles.textBody}>MP-{('0' + (array.id + 1)).slice(-2)}</Text>
            </View>
          </View>
          <View style={styles.cell}>
            <View style={styles.pad}>
              <Text style={styles.textBody}>{array.azimuth}&deg;</Text>
            </View>
          </View>
          <View style={styles.cell}>
            <View style={styles.pad}>
              <Text style={styles.textBody}>{array.pitch}&deg;</Text>
            </View>
          </View>
          <View style={styles.cell}>
            <View style={styles.pad}>
              <Text style={styles.textBody}>{array.moduleCount}</Text>
            </View>
          </View>
        </View>
      )
    }

    return (
      <>
        <Text style={styles.textDisplay}>Site Information &mdash; Wind Speed: {data.windSpeed || '0'}mph and Snow Load {data.snowLoad || '0'}psf</Text>
        <Header />
        {data.moduleArrays && data.moduleArrays.map(array => <Row key={'array-2-' + array.id} array={array} />)}
      </>
    )
  }

  function RoofFramingDetails() {
    return (
      <View>
        <Text style={styles.textDisplay}>Roof Framing Details</Text>
        {data.diagramRoofFrameDetail &&
          <Image src={data.diagramRoofFrameDetail.url} />
        }
      </View>
    )
  }

  function ConduitSchedule() {
    function Header() {
      return (
        <View style={styles.table}>
          <View style={styles.cell}>
            <View style={styles.pad}>
              <Text style={styles.textBodyBold}>Tag ID</Text>
            </View>
          </View>
          <View style={styles.cell}>
            <View style={styles.pad}>
              <Text style={styles.textBodyBold}>Conduit Size</Text>
            </View>
          </View>
          <View style={styles.cell}>
            <View style={styles.pad}>
              <Text style={styles.textBodyBold}>Conductor</Text>
            </View>
          </View>
          <View style={styles.cell}>
            <View style={styles.pad}>
              <Text style={styles.textBodyBold}>Neutral</Text>
            </View>
          </View>
          <View style={styles.cell}>
            <View style={styles.pad}>
              <Text style={styles.textBodyBold}>Ground</Text>
            </View>
          </View>
        </View>
      )
    }

    function Row({ item }) {
      return (
        <View style={styles.tableBorder}>
          <View style={styles.cell}>
            <View style={styles.pad}>
              <Text style={styles.textBody}>{item.id + 1}</Text>
            </View>
          </View>
          <View style={styles.cell}>
            <View style={styles.pad}>
              <Text style={styles.textBody}>{item.conduitLife && '(' + item.conduitLife.label + ')'} {item.conduitSize ? item.conduitSize.label : 'None'}</Text>
            </View>
          </View>
          <View style={styles.cell}>
            <View style={styles.pad}>
              {item.conductorQuantity && item.conductorQuantity !== '' ?
                <Text style={styles.textBody}>({item.conductorQuantity}) {item.conductorSize && item.conductorSize.label} {item.conductorType && item.conductorType.label}</Text>
                :
                <Text style={styles.textBody}>NONE</Text>
              }
            </View>
          </View>
          <View style={styles.cell}>
            <View style={styles.pad}>
              {item.neutralQuantity && item.neutralQuantity !== '' ?
                <Text style={styles.textBody}>({item.neutralQuantity}) {item.neutralSize && item.neutralSize.label} {item.neutralType && item.neutralType.label}</Text>
                :
                <Text style={styles.textBody}>NONE</Text>
              }
            </View>
          </View>
          <View style={styles.cell}>
            <View style={styles.pad}>
              {item.groundQuantity && item.groundQuantity !== '' ?
                <Text style={styles.textBody}>({item.groundQuantity}) {item.groundSize && item.groundSize.label} {item.groundType && item.groundType.label}</Text>
                :
                <Text style={styles.textBody}>NONE</Text>
              }
            </View>
          </View>
        </View>
      )
    }

    return (
      <View style={{...styles.table, paddingLeft: 10, paddingBottom: 10}}>
        <View style={{paddingRight: 10, paddingBottom: 10, flex: 1}}>
          <View style={{...styles.border, borderBottomWidth: 0}}>
            <Text style={{...styles.textDisplay, marginTop: 0, paddingBottom: 20}}>Conduit Schedule</Text>
            <Header />
            {data.conduitSchedule && data.conduitSchedule.map((item, index) => <Row key={'conduit-' + index} item={item} />)}
          </View>
        </View>
        <View style={{paddingRight: 10, paddingBottom: 10, flex: 1}}>
          <View style={styles.border}>
            <Text style={{...styles.textDisplay, marginTop: 0, paddingBottom: 20}}>Notes</Text>
            <View style={styles.pad}>
              <Text style={styles.textBody}>{data.electricalNotes ? data.electricalNotes : 'No notes.'}</Text>
            </View>
          </View>
        </View>
      </View>
    )
  }

  function Specifications() {
    return (
      <View>
        <View style={{paddingLeft: 10, paddingBottom: 10}}>

          <View style={styles.specFrame}>
            <View style={styles.specBox}>
              {data.moduleModel &&
                <View style={styles.border}>
                  <View style={styles.pad}>
                    <Text style={styles.textBodyBold}>Module Specs</Text>
                  </View>

                  <View style={styles.tableBorder}>
                    <View style={styles.cell}>
                      <View style={styles.pad}>
                        <Text style={styles.textBody}>Model</Text>
                      </View>
                    </View>
                    <View style={styles.cell}>
                      <View style={styles.pad}>
                        <Text style={styles.textBody}>{data.moduleModel.label}</Text>
                      </View>
                    </View>
                  </View>

                  {data.moduleModel.watts &&
                    <View style={styles.tableBorder}>
                      <View style={styles.cell}>
                        <View style={styles.pad}>
                          <Text style={styles.textBody}>Module Power @ STC</Text>
                        </View>
                      </View>
                      <View style={styles.cell}>
                        <View style={styles.pad}>
                          <Text style={styles.textBody}>{data.moduleModel.watts}W</Text>
                        </View>
                      </View>
                    </View>
                  }

                  {data.moduleModel.openCircuitVoltage &&
                    <View style={styles.tableBorder}>
                      <View style={styles.cell}>
                        <View style={styles.pad}>
                          <Text style={styles.textBody}>Open Circuit Voltage</Text>
                        </View>
                      </View>
                      <View style={styles.cell}>
                        <View style={styles.pad}>
                          <Text style={styles.textBody}>{data.moduleModel.openCircuitVoltage}A</Text>
                        </View>
                      </View>
                    </View>
                  }

                  {data.moduleModel.maxPowerVoltage &&
                    <View style={styles.tableBorder}>
                      <View style={styles.cell}>
                        <View style={styles.pad}>
                          <Text style={styles.textBody}>Max Power Voltage</Text>
                        </View>
                      </View>
                      <View style={styles.cell}>
                        <View style={styles.pad}>
                          <Text style={styles.textBody}>{data.moduleModel.maxPowerVoltage}A</Text>
                        </View>
                      </View>
                    </View>
                  }

                  {data.moduleModel.shortCircuitVoltage &&
                    <View style={styles.tableBorder}>
                      <View style={styles.cell}>
                        <View style={styles.pad}>
                          <Text style={styles.textBody}>Short Circuit Voltage</Text>
                        </View>
                      </View>
                      <View style={styles.cell}>
                        <View style={styles.pad}>
                          <Text style={styles.textBody}>{data.moduleModel.shortCircuitVoltage}A</Text>
                        </View>
                      </View>
                    </View>
                  }

                  {data.moduleModel.maxPowerCurrent &&
                    <View style={styles.table}>
                      <View style={styles.cell}>
                        <View style={styles.pad}>
                          <Text style={styles.textBody}>Max Power Current</Text>
                        </View>
                      </View>
                      <View style={styles.cell}>
                        <View style={styles.pad}>
                          <Text style={styles.textBody}>{data.moduleModel.maxPowerCurrent}A</Text>
                        </View>
                      </View>
                    </View>
                  }
                </View>
              }
            </View>

            <View style={styles.specBox}>
              {data.inverterModel &&
                <View style={styles.border}>
                  <View style={styles.pad}>
                    <Text style={styles.textBodyBold}>Inverter Specs</Text>
                  </View>

                  <View style={styles.tableBorder}>
                    <View style={styles.cell}>
                      <View style={styles.pad}>
                        <Text style={styles.textBody}>Model</Text>
                      </View>
                    </View>
                    <View style={styles.cell}>
                      <View style={styles.pad}>
                        <Text style={styles.textBody}>{data.inverterModel.label}</Text>
                      </View>
                    </View>
                  </View>

                  {data.inverterModel.powerRating &&
                    <View style={styles.tableBorder}>
                      <View style={styles.cell}>
                        <View style={styles.pad}>
                          <Text style={styles.textBody}>Power Rating</Text>
                        </View>
                      </View>
                      <View style={styles.cell}>
                        <View style={styles.pad}>
                          <Text style={styles.textBody}>{data.inverterModel.powerRating}W</Text>
                        </View>
                      </View>
                    </View>
                  }

                  {data.inverterModel.maxOutputCurrent &&
                    <View style={styles.tableBorder}>
                      <View style={styles.cell}>
                        <View style={styles.pad}>
                          <Text style={styles.textBody}>Max Output Current</Text>
                        </View>
                      </View>
                      <View style={styles.cell}>
                        <View style={styles.pad}>
                          <Text style={styles.textBody}>{data.inverterModel.maxOutputCurrent}A</Text>
                        </View>
                      </View>
                    </View>
                  }

                  {data.inverterModel.cecWeightedEfficiency &&
                    <View style={styles.tableBorder}>
                      <View style={styles.cell}>
                        <View style={styles.pad}>
                          <Text style={styles.textBody}>CEC Weighted Efficiency</Text>
                        </View>
                      </View>
                      <View style={styles.cell}>
                        <View style={styles.pad}>
                          <Text style={styles.textBody}>{data.inverterModel.cecWeightedEfficiency}%</Text>
                        </View>
                      </View>
                    </View>
                  }

                  {data.inverterModel.maxPerBranch &&
                    <View style={styles.tableBorder}>
                      <View style={styles.cell}>
                        <View style={styles.pad}>
                          <Text style={styles.textBody}>Max No Of Micro Inverters/Branch</Text>
                        </View>
                      </View>
                      <View style={styles.cell}>
                        <View style={styles.pad}>
                          <Text style={styles.textBody}>{data.inverterModel.maxPerBranch}</Text>
                        </View>
                      </View>
                    </View>
                  }

                  {data.inverterModel.maxDcVoltage &&
                    <View style={styles.table}>
                      <View style={styles.cell}>
                        <View style={styles.pad}>
                          <Text style={styles.textBody}>Max DC Voltage</Text>
                        </View>
                      </View>
                      <View style={styles.cell}>
                        <View style={styles.pad}>
                          <Text style={styles.textBody}>{data.inverterModel.maxDcVoltage}V</Text>
                        </View>
                      </View>
                    </View>
                  }
                </View>
              }
            </View>

            <View style={styles.specBox}>
            </View>

            <View style={styles.specBox}>
            </View>

          </View>
        </View>
      </View>
    )
  }
  function SpecSheets() {
    const pages = []
    function getSheets(key) {
      const item = data[key]
      if(item) {
        // get item spec sheet
        if(item.specSheets) {
          item.specSheets.forEach(sheet => {
            pages.push({
              url: sheet
            })
          })
        }
        // get included spec sheets
        if(item.includes) {
          item.includes.forEach(subItem => {
            if(subItem.specSheets) {
              subItem.specSheets.forEach(sheet => {
                pages.push({
                  url: sheet
                })
              })
            }
          })
        }
      }
    }
    const materials = [
      'moduleModel',
      'inverterModel',
      'rackingModel',
      'batteryModel'
    ]
    materials.forEach(material => getSheets(material))
    return pages.map((page, index) =>
      <Page key={'specs-' + index} size='Tabloid' orientation='landscape' style={styles.page}>
        <View style={styles.frame}>
          <View style={styles.frameMiddle}>
            <View style={styles.imageStretchFrame}>
              <Image style={{...styles.imageStretch}} src={page.url} />
            </View>
          </View>

          <View style={styles.frameRight}>
            <View></View>

            <View>
              <Info value={'SS'} label={'Spec Sheet'} />
            </View>
          </View>
        </View>
      </Page>
    )
  }

  function Info({value, label}) {
    return (
      <>
        <Text style={styles.textDisplay}>Customer Information</Text>
        <View style={styles.pad}>
          <Text style={{...styles.textBody, marginBottom: 5}}>Name: {data.name}</Text>
          <Text style={styles.textBody}>Address: {data.location && data.location.address}</Text>
          <Text style={{...styles.textBody, marginBottom: 5}}>{data.location && data.location.lat + ', ' + data.location.lng}</Text>
          <Text style={{...styles.textBody, marginBottom: 5}}>Utility: {data.utility}</Text>
          <Text style={styles.textBody}>AHJ: {data.numberAHJ}</Text>
        </View>
        <Text style={styles.textIndexTitle}>
          {value}{'\n'}
          {label}
        </Text>
        <Text style={styles.textDate}>
          {date}
        </Text>
        <Image style={styles.logo} src='/img/risingsun-logo.png' />
      </>
    )
  }

  return (
    <Document>

      <Page size='Tabloid' orientation='landscape' style={styles.page}>
        <View style={styles.frame}>

          <View style={styles.frameLeft}>
            <View>
              <SiteCatalogue />
              <ScopeOfWork />
              <ApplicableCodes />
            </View>
            <View>
              <GeneralNotes />
            </View>
          </View>

          <View style={styles.frameMiddle}>
            <View>
              {data.moduleModel && <Text style={styles.textTitle}>{data.name} &mdash; {data.moduleQuantity * data.moduleModel.watts / 1000}kW DC, {data.inverterQuantity * data.inverterModel.maxOutputCurrent * 240 / 1000}kW AC</Text>}
              <Text style={styles.textDisplay}>Site Plan</Text>
            </View>
            <View style={styles.imageStretchFrame}>
              {data.diagramSitePlan &&
                <Image style={{...styles.imageStretch, height: 705}} src={data.diagramSitePlan.url} />
              }
            </View>
          </View>

          <View style={styles.frameRight}>
            <View>
              <VicinityMap />
            </View>
            <View>
              <Info value={'T-1'} label={'Cover Page'} />
            </View>
          </View>

        </View>
      </Page>

      {data.mountingType === 'Ground Mount' &&
        <Page size='Tabloid' orientation='landscape' style={styles.page}>
          <View style={styles.frame}>

            <View style={styles.frameMiddle}>
              <View style={styles.imageStretchFrame}>
                {data.diagramPlotLines &&
                  <Image style={styles.imageStretch} src={data.diagramPlotLines.url} />
                }
              </View>
            </View>

            <View style={styles.frameRight}>
              <View></View>
              <View>
                <Info value={'T-2'} label={'Cover Page'} />
              </View>
            </View>

          </View>
        </Page>
      }

      <Page size='Tabloid' orientation='landscape' style={styles.page}>
        <View style={styles.frame}>

          <View style={styles.frameLeft}>
            <View>
              <InstallationNotes />
              {data.mountingType === 'Ground Mount' && <SiteInformationGround />}
            </View>
            <View>
              {data.mountingType === 'Roof Mount' && <RoofAccessPS />}
            </View>
          </View>

          <View style={styles.frameMiddle}>
            <View></View>
            <View style={styles.imageStretchFrame}>
              {data.diagramMountingDetail &&
                <Image style={{...styles.imageStretch}} src={data.diagramMountingDetail.url} />
              }
            </View>
          </View>

          <View style={styles.frameRight}>
            <View>
              <AerialView />
            </View>
            <View>
              <Info value={'M-1'} label={'Mounting Detail'} />
            </View>
          </View>
        </View>
      </Page>

      {data.mountingType === 'Roof Mount' &&
        <Page size='Tabloid' orientation='landscape' style={styles.page}>
          <View style={styles.frame}>

            <View style={styles.frameMiddle}>
              <View style={styles.infoFrame}>
                <View>
                  <SiteInformation />
                </View>
                <View>
                  <RoofFramingDetails />
                </View>
              </View>
            </View>

            <View style={styles.frameRight}>
              <View></View>

              <View>
                <Info value={'M-2'} label={'Mounting Detail'} />
              </View>
            </View>
          </View>
        </Page>
      }

      <Page size='Tabloid' orientation='landscape' style={styles.page}>
        <View style={styles.frame}>
          <View style={styles.frameMiddle}>
            <View style={styles.imageStretchFrame}>
              <Image style={{...styles.imageStretch}} src='/img/structural-detail.png' />
            </View>
          </View>

          <View style={styles.frameRight}>
            <View></View>

            <View>
              <Info value={'M-3'} label={'Structural Detail'} />
            </View>
          </View>
        </View>
      </Page>

      <Page size='Tabloid' orientation='landscape' style={styles.page}>
        <View style={styles.frame}>
          <View style={styles.frameMiddle}>
            <View style={styles.elecFrame}>
              <View>
                {singleLine && <Image src={singleLine} />}
              </View>
              <View>
                <ConduitSchedule />
                <Specifications />
              </View>

            </View>
          </View>

          <View style={styles.frameRight}>
            <View></View>

            <View>
              <Info value={'E-1'} label={'Single Line Diagram'} />
            </View>
          </View>
        </View>
      </Page>


      <Page size='Tabloid' orientation='landscape' style={styles.page}>
        <View style={styles.frame}>
          <View style={styles.frameMiddle}>
            <View style={styles.placardFrame}>
              <View style={styles.warningPlacard}>
                {data.codeElectrical && data.codeElectrical.imgSrc &&
                  <Image src={data.codeElectrical.imgSrc} />
                }
              </View>
              <View style={styles.warningDiagram}>
                <View style={{...styles.pad, ...styles.warningDiagramFrame}}>
                  <Text style={styles.textWarningTitle}>WARNING</Text>
                  <Text style={styles.textWarning}>POWER TO THIS BUILDING IS ALSO SUPPLIED FROM THE FOLLOWING SOURCES WITH DISCONNECTS LOCATED AS SHOWN</Text>
                  {data.diagramWarningPlan &&
                    <Image src={data.diagramWarningPlan.url} />
                  }
                </View>
                <Text style={styles.textBody}>ALL PLACARDS SHALL BE OF WEATHER PROOF CONSTRUCTION, BACKGROUND ON ALL PLACARDS SHALL BE RED WITH WHITE LETTERING U.O.N.</Text>
                <Text style={styles.textBody}>PLACARD SHALL BE MOUNTED DIRECTLY ON THE EXISTING UTILITY ELECTRICAL SERVICE.</Text>
                <Text style={styles.textBody}>FASTENERS APPROVED BY THE LOCAL JURISDICTION</Text>
              </View>
            </View>
          </View>

          <View style={styles.frameRight}>
            <View></View>

            <View>
              <Info value={'PL-1'} label={'Warning Placards'} />
            </View>
          </View>
        </View>
      </Page>

      <SpecSheets />

    </Document>
  )
}
