import { useState, useEffect, useRef } from 'react'

import * as L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet.gridlayer.googlemutant'

export default function MapAerial({ lat, lng, zoom, marker, onChange, setSnapshot }) {
  const mapEl = useRef(null)
  const [globalMap, setGlobalMap] = useState(null)

  useEffect(() => {
    const map = L.map('aerial', {
      center: [lat, lng],
      zoom: zoom,
      scrollWheelZoom: false,
      zoomControl: false
    })

    const tileLayer = L.gridLayer.googleMutant({
      type: 'satellite'
    })
    tileLayer.addTo(map)

    const myIcon = L.divIcon({
        className: 'custom-div-icon',
        html: `<div class='h-full w-full rounded-full bg-primary' />`,
        iconSize: [20, 20],
        iconAnchor: [10, 40]
    })
    L.marker(marker, {icon: myIcon}).addTo(map)

    map.on('zoomend', () => {
      const center = map.getCenter()
      const zoom = map.getZoom()
      onChange({ lat: center.lat, lng: center.lng, zoom: zoom })
    })

    map.on('moveend', () => {
      const center = map.getCenter()
      const zoom = map.getZoom()
      onChange({ lat: center.lat, lng: center.lng, zoom: zoom })
    })

    setGlobalMap(map)

    return () => {
      map.remove()
      setGlobalMap(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

	useEffect(() => {
    if(globalMap) {
      globalMap.setView([lat, lng], zoom)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lat, lng, zoom])

  const MapButton = ({ children, onClick }) => {
    return (
      <button className='h-10 w-10 rounded-full bg-gray-900 flex' onClick={onClick}>
        <span className='m-auto'>{children}</span>
      </button>
    )
  }

  return (
		<div className='aspect-ratio-square relative z-0'>
			<ul className='absolute top-0 left-0 z-10 grid grid-flow-col auto-cols-min gap-2 m-2'>
				<li>
					<MapButton onClick={() => globalMap.setZoom(globalMap.getZoom() + 1)}>
						<svg className='h-5 w-5' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
							<path strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.6} d='M12 6v6m0 0v6m0-6h6m-6 0H6' />
						</svg>
					</MapButton>
				</li>
				<li>
					<MapButton onClick={() => globalMap.setZoom(globalMap.getZoom() - 1)}>
						<svg className='h-4 w-4' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
							<path strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.75} d='M20 12H4' />
						</svg>
					</MapButton>
				</li>
			</ul>
      <div className='absolute h-full w-full rounded-xl overflow-hidden'>
        <div ref={mapEl} id='aerial' className='w-full h-full z-0 rounded-xl overflow-hidden' />
      </div>
    </div>
  )
}
