import Autocomplete from 'react-google-autocomplete'

export default function AddressBar({ currentLocation, onChange }) {
  return (
    <Autocomplete
      className='input'
      onPlaceSelected={(place) => {
        onChange(place)
        console.log(place)
      }}
      placeholder={currentLocation ? currentLocation : 'Enter an address.'}
      types={['address']}
      componentRestrictions={{country: 'us'}} />
  )
}
