export const moduleOptions = [
  {
    value: 'rec-alpha-365AA-black',
    label: 'REC Alpha 365AA Black',
		specSheets: ['/img/spec-sheets/module/rec-alpha-365-black.jpg'],
    watts: 365,
    height: 67.75,
    width: 40,
    cost: 3,
    openCircuitVoltage: 44.0,
    maxPowerVoltage: 1000,
    shortCircuitVoltage: 10.52,
    maxPowerCurrent: 9.85
  }
]

export const inverterOptions = [
  {
    value: 'enphase-iq7+',
    label: 'Enphase IQ7 +',
    type: 'micro',
		specSheets: ['/img/spec-sheets/inverter/enphase-iq7-plus.jpg'],
    powerRating: 290,
    maxOutputCurrent: 1.21,
    cecWeightedEfficiency: 97.0,
    maxPerBranch: 13,
    maxDcVoltage: 60,
		includes: [{
			value: 'combiner-X-IQ-AM1-240-2',
			label: 'Combiner X-IQ-AM1-240-2'
		},{
			value: 'external-envoy-ENV-1Q-AM1-240',
			label: 'External Envoy ENV-1Q-AM1-240'
		}]
  },{
    value: 'solar-edge-SE7600H',
    label: 'Solar Edge SE7600H',
    type: 'string',
		specSheets: ['/img/spec-sheets/inverter/solar-edge.jpg'],
    powerRating: 7600,
    maxOutputCurrent: 32,
    cecWeightedEfficiency: 99.0,
    maxDcVoltage: 11800,
		includes: [{
			value: 'optimizer-P340',
			label: 'Optimizer P340',
			specSheets: ['/img/spec-sheets/optimizer/solar-edge.jpg'],
		}]
  }
]

export const rackOptions = [
  {
    value: 'iron-ridge-tilt-mount',
    label: 'Iron Ridge Tilt Mount',
		specSheets: ['/img/spec-sheets/racking/iron-ridge-roof-tilt-mount.jpg'],
		includes: [{
			value: 'iron-ridge-rail',
			label: 'Iron Ridge Rail',
			specSheets: ['/img/spec-sheets/rail/iron-ridge.jpg'],
		}]
  },{
    value: 'iron-ridge-flush-mount',
    label: 'Iron Ridge Flush Mount',
		specSheets: ['/img/spec-sheets/racking/iron-ridge-flush-mount.jpg'],
		includes: [{
			value: 'iron-ridge-rail',
			label: 'Iron Ridge Rail',
			specSheets: ['/img/spec-sheets/rail/iron-ridge.jpg'],
		}]
  },{
    value: 'iron-ridge-ground-mount',
    label: 'Iron Ridge Ground Mount',
		specSheets: ['/img/spec-sheets/racking/iron-ridge-ground-mount.jpg'],
		includes: [{
			value: 'iron-ridge-rail',
			label: 'Iron Ridge Rail',
			specSheets: ['/img/spec-sheets/rail/iron-ridge.jpg'],
		}]
  }
]

export const batteryOptions = [
  {
    value: 'panasonic-evervolt-ac',
    label: 'Panasonic Evervolt AC',
  },{
    value: 'tesla-powerwall-2',
    label: 'Tesla Powerwall 2',
		specSheets: ['/img/spec-sheets/battery/tesla-powerwall-2.jpg'],
		includes: [{
			value: 'backup-gateway',
			label: 'Backup Gateway',
			specSheets: ['/img/spec-sheets/backup-gateway/tesla-powerwall.jpg'],
		}]
  }
]
