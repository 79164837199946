import useStorage from 'lib/useStorage'

import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'

import Resizer from 'react-image-file-resizer'

export function UploadDiagram({ data, fileName, onChange }) {
  const storage = useStorage()
  const storagePath = 'images/plans/' + data.id + '/' + fileName
  const filePath = storagePath + '/' + fileName + '.jpg'

  const [isUploading, setIsUploading] = useState(false)

  function resizeFile(file, maxWidth=1200, maxHeight=2400) {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(file, maxWidth, maxHeight, 'JPEG', 80, 0, (uri) => { resolve(uri) }, 'file')
    })
  }

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0]
    upload(file)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({onDrop, accept: 'image/*'})

  async function upload(file) {
    setIsUploading(true)
    const image = await resizeFile(file)
    storage.uploadImage(image, filePath)
    .then(image => {
      onChange(image, fileName)
      setIsUploading(false)
    })
    .catch(error => {
      console.log(error)
      setIsUploading(false)
    })
  }

  function remove() {
    storage.deleteFolder(storagePath)
    .then(() => {
      onChange(null, fileName)
    })
  }

  if(isUploading) {
    return (
      <div>
        <div className='relative text-xs cursor-pointer rounded-xl border-2 border-gray-800 focus:outline-none text-center text-sm leading-tight' {...getRootProps()}>
          <div className='aspect-ratio-16/9'></div>
          <div className='absolute left-0 top-0 w-full h-full flex items-center justify-center'>
            <div className='p-4'>
              Uploading...
            </div>
          </div>
        </div>
      </div>
    )
  }

  if(!data[fileName]) {
    return (
      <div>
        <div className='relative text-xs cursor-pointer rounded-xl border-2 border-gray-800 hover:border-primary transition duration-150 focus:outline-none text-center text-sm leading-tight' {...getRootProps()}>
          <div className='aspect-ratio-16/9'></div>
          <div className='absolute left-0 top-0 w-full h-full flex items-center justify-center'>
            <div className='p-4'>
              <input {...getInputProps()} />
              {isDragActive ?
                <div><p>Drop the files here ...</p></div> :
                <div>
                  <svg width='40' className='text-primary inline-block pb-4' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={1} d='M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12' />
                  </svg>
                  <p className='text-gray-700'>Drag 'n' drop an image here,<br />or click to select a file.</p>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  if(data[fileName]) {
    return (
      <div className='relative'>
        <div className='relative border-2 border-gray-800 rounded-xl overflow-hidden'>
          <div className='aspect-ratio-16/9'></div>
          <div className='absolute left-0 top-0 w-full h-full bg-gray-800'>
            <img
              className='w-full h-full w-full object-contain'
              alt='Feature'
              src={data[fileName].small ? data[fileName].small : data[fileName].url} />
          </div>
        </div>
        <button
          className='absolute top-0 right-0 m-2 btn-circle-gray hover:text-primary hover:bg-gray-900'
          onClick={() => remove()}>
          <svg className='m-auto w-5 h-5' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5} d='M6 18L18 6M6 6l12 12' />
          </svg>
        </button>
      </div>
    )
  }
}
