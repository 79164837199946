import { Link } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Menu from 'components/menu'

export default function Layout({ children }) {
  return (
    <div className='min-h-screen flex flex-col'>
      <header>
        <div className='p-6 fixed top-0 left-0 z-20'>
          <Link
            className={'group border-2 border-current block h-16 w-16 duration-150 transform hover:scale-105 flex rounded-xl bg-gray-900 text-primary'}
            to='/'>
            <span className='flex items-center justify-center absolute top-0 left-0 h-full w-full text-2xl block transform scale-100 opacity-100 duration-150 group-hover:scale-0 group-hover:opacity-0'>
              <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2' />
              </svg>
            </span>
            <span className='flex items-center justify-center absolute top-0 left-0 h-full w-full text-xs block transform scale-0 opacity-0 duration-150 group-hover:scale-100 group-hover:opacity-100'>Control</span>
          </Link>
        </div>
        <div className='p-6 fixed top-0 right-0 z-20'>
          <Menu />
        </div>
      </header>
      <div className='fade-in'>
        {children}
      </div>
      <ToastContainer />
    </div>
  )
}
