import { useState, useEffect } from 'react'

import ReactFlow, { ReactFlowProvider } from 'react-flow-renderer'

import { generateLayout, nodeTypes } from './single-line-layout-generator.js'

export default function SingleLineSnapShot({ data }) {
  const [flow, setFlow] = useState(false)
  const [elements, setElements] = useState([])

  useEffect(() => {
    if(flow) {
      const newElements = generateLayout(data)
      setElements(newElements)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if(flow) setTimeout(() => flow.setTransform({ x: 20, y: 160, zoom: 1.8 }), 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elements])

  const onLoad = (reactFlowInstance) => {
    setFlow(reactFlowInstance)
    reactFlowInstance.setTransform({ x: 20, y: 175, zoom: 2 })
  }

  return (
    <div id='flow-container-snap-shot overflow-visible'>
      <div id='single-line-flow-chart-snap-shot' style={{height: 675, width: 2200}} className='bg-white'>
        <ReactFlowProvider>
          <ReactFlow
            nodeTypes={nodeTypes}
            elements={elements}
            onLoad={onLoad}
            snapToGrid={true}
            snapGrid={[10, 10]}>
          </ReactFlow>
        </ReactFlowProvider>
      </div>
    </div>
  )
}
